import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  TextField,
  Dialog,
  LinearProgress,
  CircularProgress,
  Box,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TagDialog = (props) => {
  const {
    open,
    toggle,
    createTag,
    getTags,
    deleteTag,
    updateTag,
    showSnackbar,
  } = props;

  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [fetchingTags, setFetchingTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [updatedValue, setUpdatedValue] = useState("");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [tag, setTag] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const initialize = async () => {
    if (open) setLoading(true);
    await fetchTags();
    setTag("");
    setSelectedTag(null);
    setUpdatedValue("");
    setSubmitting(false);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, [open]);

  const fetchTags = async () => {
    setFetchingTags(true);
    const tags = await getTags();
    setFetchedTags(tags?.data || []);
    setFetchingTags(false);
  };

  const handleTagSelection = (event, value) => {
    setSelectedTag(value);
    setUpdatedValue(value?.tag || "");
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    const submitted = await createTag(tag);
    if (submitted?.success) {
      showSnackbar({ type: "success", message: "Tag Created!" });
    } else {
      showSnackbar({
        type: "error",
        message: submitted?.error || "Something went wrong.",
      });
    }
    toggle();
    setSubmitting(false);
  };

  const deleteTagCall = async () => {
    setSubmitting(true);
    const submitted = await deleteTag(selectedTag._id);
    if (submitted?.success) {
      showSnackbar({ type: "success", message: "Tag deleted!" });
    } else {
      showSnackbar({
        type: "error",
        message: submitted?.error || "Something went wrong.",
      });
    }
    toggle();
    setSubmitting(false);
  };

  const updateTagCall = async () => {
    setSubmitting(true);
    const submitted = await updateTag(selectedTag._id, updatedValue);
    if (submitted?.success) {
      showSnackbar({ type: "success", message: "Tag updated!" });
    } else {
      showSnackbar({
        type: "error",
        message: submitted?.error || "Something went wrong.",
      });
    }
    toggle();
    setSubmitting(false);
  };

  if (loading) {
    return (
      <div class="fixed top-0 left-0 bottom-0 right-0 bg-white bg-opacity-20 flex justify-center items-center z-50 transition-all ease-in-out duration-200">
        <LinearProgress style={{ width: "22%", zIndex: 1003 }} />
      </div>
    );
  }

  return (
    <Dialog open={open} onClose={toggle} TransitionComponent={Transition}>
      <Box sx={{ bgcolor: "#fafafa", color: "#CF92D9" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Create Tag"
            sx={{
              textTransform: "none",
            }}
          />
          <Tab
            label="Update Tag"
            sx={{
              textTransform: "none",
            }}
          />
          <Tab
            label="Delete Tag"
            sx={{
              color: "red",
              textTransform: "none",
              "&.Mui-selected": {
                color: "red",
              },
            }}
          />
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          style={{
            width: "100%",
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="tag"
            size="small"
            label="Tag"
            fullWidth
            variant="outlined"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            style={{
              minWidth: "30vw",
            }}
          />
          <div className="flex w-full justify-end items-center pt-4 self-end">
            <Button
              onClick={toggle}
              variant="outlined"
              style={{
                textTransform: "capitalize",
                background: "white",
                fontSize: ".9rem",
                color: "gray",
                border: "1px solid #aaa",
                marginRight: ".4rem",
                fontWeight: 700,
              }}
              size="small"
              disabled={submitting}
            >
              Close
            </Button>
            <Button
              onClick={(event) => !submitting && handleSubmit(event)}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "dodgerblue",
                color: "white",
                fontSize: ".9rem",
                textTransform: "capitalize",
              }}
            >
              {submitting ? (
                <CircularProgress
                  size={22}
                  style={{
                    padding: ".2rem",
                    color: "white",
                  }}
                />
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div
            style={{
              minWidth: "30vw",
            }}
          >
            <Autocomplete
              id="tags-outlined"
              className="mt-4"
              size="small"
              options={fetchedTags || []}
              onChange={handleTagSelection}
              disabled={fetchingTags}
              value={selectedTag}
              isOptionEqualToValue={(option, value) => option._id == value._id}
              getOptionLabel={(option) => option.tag}
              // filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Tag"
                  placeholder="Type tag name here..."
                />
              )}
            />
            <TextField
              autoFocus
              margin="dense"
              id="tag"
              size="small"
              label="new tag value"
              fullWidth
              variant="outlined"
              value={updatedValue}
              onChange={(e) => setUpdatedValue(e.target.value)}
              disabled={submitting || !selectedTag}
              style={{
                minWidth: "30vw",
                marginTop: "1rem",
              }}
            />
            <div className="flex w-full justify-end items-center pt-4 self-end">
              <Button
                onClick={toggle}
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  background: "white",
                  fontSize: ".9rem",
                  color: "gray",
                  border: "1px solid #aaa",
                  marginRight: ".4rem",
                  fontWeight: 700,
                }}
                size="small"
                disabled={submitting}
              >
                Close
              </Button>
              <Button
                onClick={(event) => !submitting && updateTagCall(event)}
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "dodgerblue",
                  color: "white",
                  fontSize: ".9rem",
                  textTransform: "capitalize",
                }}
              >
                {submitting ? (
                  <CircularProgress
                    size={22}
                    style={{
                      padding: ".2rem",
                      color: "white",
                    }}
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div
            style={{
              minWidth: "30vw",
            }}
          >
            <Autocomplete
              id="tags-outlined"
              className="mt-4"
              options={fetchedTags || []}
              size="small"
              onChange={handleTagSelection}
              disabled={fetchingTags}
              value={selectedTag}
              isOptionEqualToValue={(option, value) => option._id == value._id}
              getOptionLabel={(option) => option.tag}
              // filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Tag"
                  placeholder="Type tag name here..."
                />
              )}
            />
            <div className="flex w-full justify-end items-center pt-4 self-end">
              <Button
                onClick={toggle}
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  background: "white",
                  fontSize: ".9rem",
                  color: "gray",
                  border: "1px solid #aaa",
                  marginRight: ".4rem",
                  fontWeight: 700,
                }}
                size="small"
                disabled={submitting}
              >
                Close
              </Button>
              <Button
                onClick={(event) => !submitting && deleteTagCall(event)}
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontSize: ".9rem",
                  textTransform: "capitalize",
                }}
              >
                {submitting ? (
                  <CircularProgress
                    size={22}
                    style={{
                      padding: ".2rem",
                      color: "white",
                    }}
                  />
                ) : (
                  "Delete"
                )}
              </Button>
            </div>
          </div>
        </TabPanel>
      </SwipeableViews>
    </Dialog>
  );
};

export default TagDialog;
