import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import GithubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { makeStyles } from "@mui/styles";

import { IconButton } from "@mui/material";
import { connect } from "react-redux";

//pages
import Dashboard from "./dashboard";
import TestPage from "./testPage";
import TestDetailPage from "./testDetailPage";
import CategoryPage from "./categoriesPage";

// components
import Sidebar from "../components/Sidebar";
import Footer from "../components/footer";
import { Link } from "@mui/material";

import BreadCrumbs from "../components/breadcrumbs";

//Sidebar structure
import structure from "../components/SidebarStructure";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    position: "relative",
    flexGrow: 1,
    margin: "0px 35px 0px 35px",
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    paddingLeft: (props) =>
      `${props?.isSidebarOpened ? "17vw !important" : "6vw"}`,
    paddingBottom: 70,
  },
  contentShift: {
    width: `calc(100vw - (240px + ${theme.spacing(8)}))`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    marginTop: 30,
  },
  link: {
    marginRight: `16px !important`,
    textDecoration: "none",
  },
  defaultRadio: {
    color: "#536DFE",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#536DFE",
    },
  },
  successRadio: {
    color: "#23a075",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#23a075",
    },
  },
  secondaryRadio: {
    color: "#FF5C93",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#FF5C93",
    },
  },
  warningRadio: {
    color: "#FFC260",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#FFC260",
    },
  },
  infoRadio: {
    color: "#9013FE",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#9013FE",
    },
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
  ecommerceIcon: {
    color: "#6E6E6E",
  },
  calendarIcon: {
    color: theme.palette.primary.main,
    marginRight: 14,
  },
  margin: {
    marginBottom: 24,
  },
  changeThemeFab: {
    position: "fixed",
    top: theme.spacing(15),
    right: 0,
    zIndex: 1,
    borderRadius: 0,
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  navPadding: {
    paddingTop: `${theme.spacing(1)}px !important`,
    paddingBottom: `6px !important`,
  },
  date: {
    marginRight: 38,
    color: theme.palette.type === "dark" ? "#D6D6D6" : "#4A494A",
  },
}));

const Layout = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { isSidebarOpened } = props;

  const classes = useStyles({ isSidebarOpened });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
  };

  return (
    <div className={classes.root}>
      {/* <Header history={props.history} /> */}
      <Sidebar structure={structure} />
      <div
        className={`${classes.content} ${
          isSidebarOpened ? classes.contentShift : ""
        }`}
        style={{
          paddingLeft: isSidebarOpened ? "17vw" : "6vw",
        }}
      >
        <div className={classes.fakeToolbar} />
        {/* <BreadCrumbs /> */}
        <Switch>
          <Route path="/app/dashboard" component={Dashboard} exact />
          <Route path="/app/tests" component={TestPage} exact />
          <Route path="/app/tests/:id" component={TestDetailPage} exact />
          <Route path="/app/categories" component={CategoryPage} exact />
        </Switch>
        {/* <Footer>
          <div>
            <Link
              color={"primary"}
              href={"https://delvex.com/"}
              target={"_blank"}
              className={classes.link}
            >
              delvex
            </Link>
            <Link
              color={"primary"}
              href={"https://delvex.com/about"}
              target={"_blank"}
              className={classes.link}
            >
              About Us
            </Link>
            <Link
              color={"primary"}
              href={"https://delvex.com/blog"}
              target={"_blank"}
              className={classes.link}
            >
              Blog
            </Link>
          </div>
          <div>
            <Link href={"https://www.facebook.com/delvex"} target={"_blank"}>
              <IconButton aria-label="facebook">
                <FacebookIcon style={{ color: "#6E6E6E99" }} />
              </IconButton>
            </Link>
            <Link href={"https://twitter.com/delvex"} target={"_blank"}>
              <IconButton aria-label="twitter">
                <TwitterIcon style={{ color: "#6E6E6E99" }} />
              </IconButton>
            </Link>
            <Link href={"https://github.com/delvex"} target={"_blank"}>
              <IconButton
                aria-label="github"
                style={{ padding: "12px 0 12px 12px" }}
              >
                <GithubIcon style={{ color: "#6E6E6E99" }} />
              </IconButton>
            </Link>
          </div>
        </Footer> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSidebarOpened: state?.layout?.isSidebarOpened,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
