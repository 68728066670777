import React, { useCallback, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField as Input,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import {
  Inbox as InboxIcon,
  ExpandMore as ExpandIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

// components
import { Button, Badge, Link as LinkMaterial } from "@mui/material";

// styles
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    marginBottom: ".4rem",
    cursor: "pointer !important",
    "&:hover": {
      backgroundColor: (props) =>
        props?.label === "Logout" ? "#f2c9c9" : "#EBEEFC",
      color: "#3C68FA",
      "& > .MuiSvgIcon-root": {
        display: "inline-block",
      },
      borderRadius: ".7rem",
      margin: "0 .4rem",
      marginBottom: ".4rem",
      paddingLeft: ".6rem",
      maxWidth: "95%",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
  linkActive: {
    backgroundColor: "#EBEEFC",
    margin: "0 .4rem",
    paddingLeft: ".6rem",
    marginBottom: ".4rem",
    maxWidth: "95%",
    borderRadius: ".7rem",
  },
  linkNested: {
    paddingLeft: 25,
    "&:hover, &:focus": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.background.light
          : "#FFFFFF",
    },
  },
  linkIcon: {
    color: "#66676E !important",
    // color:
    //   theme.palette.type === "dark"
    //     ? "#616168 !important"
    //     : theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    // color: `${theme.palette.primary.main} !important`,
    color: "#3C68FA !important",
  },
  linkText: {
    padding: 0,
    color: (props) => (props?.label === "Logout" ? "#B23B3B" : "#585B5E"),
    // color:
    //   theme.palette.type === "dark"
    //     ? "#D6D6D6 !important"
    //     : theme.palette.text.secondary + "CC",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
  },
  linkTextActive: {
    // color: theme.palette.text.primary,
    color: "#3C68FA !important",
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {},
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 1,
    backgroundColor:
      theme.palette.type === "dark" ? "rgba(151,151,151, .15)" : "#D8D8D880",
  },
  expand: {
    transform: "rotate(180deg)",
  },
  expandWrapper: {
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("transform"),
    display: (props) => (props ? "inline-flex" : "none"),
    marginLeft: "auto",
  },
  nestedMenu: {
    paddingLeft: 0,
  },
  nestedMenuItem: {
    paddingLeft: 0,
  },
  popover: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  noBoxShadow: {
    boxShadow: "none !important",
    marginRight: theme.spacing(1),
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#fff",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(255, 255, 255, .45)",
    },
  },
  buttonLabel: {
    color: "#fff",
  },
}));

const SidebarLink = ({
  link,
  ext,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  toggleDrawer,
  click,
  logout,
  toggleQuestionDialog,
  toggleTagDialog,
  ...props
}) => {
  const history = useHistory();
  // local
  let [isOpen, setIsOpen] = useState(false);
  // Add Section Popover state
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Login page onClick
  function onLogin() {
    localStorage.removeItem("token");
    window.location.reload();
  }

  onLogin.clickName = "onLogin";

  let classes = useStyles({ label });

  const goto = useCallback((path) => history.push(path), [history]);

  let isLinkActive =
    link && (location.pathname === link || location.pathname.includes(link));

  if (type === "title")
    return (
      <Typography
        className={`${classes.linkText} ${classes.sectionTitle} ${
          !isSidebarOpened ? classes.linkTextHidden : ""
        }`}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;

  if (type === "margin") return <section style={{ marginTop: 240 }} />;

  // Add Section Popover

  const open = Boolean(anchorEl);
  const id = open ? "add-section-popover" : undefined;

  function addSectionClick(event) {
    setAnchorEl(event.currentTarget);
  }

  addSectionClick.clickName = "addSectionClick";

  const addSectionClose = () => {
    setAnchorEl(null);
  };

  const clickHandler = async (label) => {
    switch (label) {
      case "Logout": {
        return logout();
      }
      case "Add Question": {
        return toggleQuestionDialog();
      }
      case "Tag": {
        return toggleTagDialog();
      }
      default: {
        return;
      }
    }
  };

  if (!children && ext)
    return (
      <>
        <ListItem
          onClick={(e) => {
            history.push(location);
            return toggleDrawer(e);
          }}
          onKeyPress={toggleDrawer}
          component={link ? LinkMaterial : null}
          className={classes.link}
          classes={{
            root: `${classes.link} ${
              isLinkActive && !nested ? classes.linkActive : ""
            }
            ${nested ? classes.linkNested : ""}
            `,
          }}
          style={
            isLinkActive
              ? {
                  borderRadius: "14px !important",
                  margin: "1rem !important",
                }
              : {}
          }
        >
          <ListItemIcon
            className={`${classes.linkIcon} ${
              isLinkActive ? classes.linkIconActive : ""
            }`}
            style={{ margin: nested && -11 }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: `${classes.linkText} ${
                isLinkActive ? classes.linkTextActive : ""
              } ${!isSidebarOpened ? classes.linkTextHidden : ""}`,
            }}
            primary={label}
          />
        </ListItem>
      </>
    );
  if (!children)
    return (
      <>
        <ListItem
          onClick={(e) => {
            if (type === "button") {
              return clickHandler(label);
            }
            if (click) {
              return click(e, addSectionClick, onLogin);
            }
            return toggleDrawer(e);
          }}
          onKeyPress={toggleDrawer}
          component={link ? Link : null}
          to={link}
          className={classes.link}
          classes={{
            root: `${classes.link} ${
              isLinkActive && !nested ? classes.linkActive : ""
            }`,
          }}
        >
          <ListItemIcon
            className={`${classes.linkIcon} ${
              isLinkActive ? classes.linkIconActive : ""
            }`}
            style={{ margin: nested && -11 }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: `${classes.linkText} ${
                isLinkActive ? classes.linkTextActive : ""
              } ${!isSidebarOpened ? classes.linkTextHidden : ""}`,
            }}
            primary={label}
          />
        </ListItem>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={addSectionClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.popover }}
        >
          <Box m={3} display="flex" flexDirection="column">
            <Typography>Add section</Typography>
            <Input
              placeholder="Section Name"
              classes={{ root: classes.input }}
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.noBoxShadow}
              >
                Add
              </Button>
              <Button
                classes={{ label: classes.buttonLabel }}
                onClick={addSectionClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </>
    );

  return (
    <>
      {props.badge ? (
        <ListItem
          button
          component={link && Link}
          onClick={toggleCollapse}
          className={`${classes.link}
            ${isLinkActive ? classes.linkActive : ""}
            ${type === "nested" ? classes.nestedMenu : ""}
          `}
          to={link}
          disableRipple
        >
          <ListItemIcon
            className={`${classes.linkIcon} ${
              isLinkActive ? classes.linkIconActive : ""
            }`}
          >
            {icon ? icon : <InboxIcon />}
          </ListItemIcon>
          <Badge badgeContent={props.badge} color={props.badgeColor}>
            <ListItemText
              classes={{
                primary: `${classes.linkText} ${
                  isLinkActive ? classes.linkTextActive : ""
                } ${!isSidebarOpened ? classes.linkTextHidden : ""}`,
              }}
              primary={label}
            />
          </Badge>
          <ExpandIcon
            className={`
              ${classes.expandWrapper}
              ${isOpen ? classes.expand : ""} ${
              !isSidebarOpened ? classes.linkTextHidden : ""
            }`}
          />
        </ListItem>
      ) : (
        <ListItem
          button
          component={link && Link}
          onClick={toggleCollapse}
          className={`${classes.link} ${isLinkActive ? classes.linkActive : ""}
            ${type === "nested" ? classes.nestedMenu : ""}`}
          to={link}
          disableRipple
        >
          <ListItemIcon
            className={`${classes.linkIcon} ${
              isLinkActive ? classes.linkIconActive : ""
            }`}
          >
            {icon ? icon : <InboxIcon />}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: `${classes.linkText} ${
                isLinkActive ? classes.linkTextActive : ""
              } ${!isSidebarOpened ? classes.linkTextHidden : ""}`,
            }}
            primary={label}
          />
          <ExpandIcon
            className={`
              ${classes.expandWrapper}
              ${isOpen ? classes.expand : ""} ${
              !isSidebarOpened ? classes.linkTextHidden : ""
            }`}
          />
        </ListItem>
      )}
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={`${classes.nestedList}
            ${type === "nested" ? classes.nestedMenuItem : ""}
          `}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                key={(childrenLink && childrenLink.link) || childrenLink.label}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                toggleDrawer={toggleDrawer}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
};

export default SidebarLink;
