import axios from "../../utilities/axios";

export const getTags = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.get("/tag", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return {
        error: null,
        success: resp.status == 200,
        data: resp?.data?.tags || [],
      };
    } catch (error) {
      return {
        error: error?.response?.data || "Something went wrong",
        success: false,
      };
    }
  };
};

export const createTag = (value) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.post(
        "/tag",
        { tag: value },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return {
        error: null,
        success: resp.status == 200,
      };
    } catch (error) {
      return {
        error: error?.response?.data || "Something went wrong",
        success: false,
      };
    }
  };
};

export const updateTag = (id, tag) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.post(
        "/tag/update/" + id,
        { tag },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return {
        error: null,
        success: resp.status == 200,
      };
    } catch (error) {
      return {
        error: error?.response?.data || "Something went wrong",
        success: false,
      };
    }
  };
};

export const deleteTag = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.post(
        "/tag/delete/" + id,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return {
        error: null,
        success: resp.status == 200,
      };
    } catch (error) {
      return {
        error: error?.response?.data || "Something went wrong",
        success: false,
      };
    }
  };
};
