import { LOGGED_IN, LOGOUT, SET_PROFILE } from "../actions/actionTypes";

const initialState = {
  isAuthenticated: null,
  profile: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN: {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case SET_PROFILE: {
      return {
        ...state,
        isAuthenticated: true,
        profile: action.payload?.profile,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
