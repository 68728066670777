import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Eye, EyeOff } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { withFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import { setProfile, signin } from "../store/actions/auth.actions";
import { showSnackbar } from "../store/actions/snackbar.actions";

const useStyles = makeStyles((theme) => ({
  container: {},
  box1: {},
  move: {},
  header: {
    fontSize: "1rem",
    fontWeight: "500",
    fontFamily: "Raleway",
    margin: "0 .5rem",
    color: "#fffff",
  },
}));

const LoginPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [showPass, setShowPass] = useState(false);
  const [authLoader, setAuthLoader] = useState(false);

  const registered = history?.location?.state?.registered;

  const {
    values,
    errors,
    touched,
    setErrors,
    setTouched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,

    setProfile,
    isAuthenticated,
  } = props;

  const toggleShowPass = () => {
    setShowPass((state) => !state);
  };

  const authenticateUser = async (token) => {
    setAuthLoader(true);
    await setProfile(token);
    setAuthLoader(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    if (token) authenticateUser(token);
  }, [location?.search]);

  return (
    <div
      className={`flex w-screen h-screen overflow-hidden transition-all ease-in-out bg-slate-100
       ${classes.container}`}
    >
      <div
        className={`flex flex-col justify-center items-center w-full bg-white ${classes.box1}`}
      >
        <div
          className={`h-5/6 flex flex-col w-4/5 self-center items-center justify-start py-14`}
          data-aos="zoom-in"
          style={{
            boxShadow: "4px 8px 14px 4px #eaeaea",
            borderRadius: "8px",
          }}
        >
          <div
            className="flex items-start"
            style={{
              paddingBottom: "4%",
            }}
          >
            <img
              src="/images/logo.png"
              width="40"
              height="40"
              style={{
                zIndex: 1400,
                marginBottom: "3rem",
              }}
              data-aos="zoom-in"
              data-aos-delay={200}
            />
            <span
              className="text-2xl font-semibold text-slate-700 pl-4 pt-1"
              data-aos="fade-left"
              data-aos-delay="500"
              style={{
                background: "rgb(79,126,247)",
                background:
                  "linear-gradient(45deg, rgba(79,126,247,1) 14%, rgba(10,127,251,1) 22%, rgba(111,181,251,1) 27%, rgba(10,127,251,1) 32%, rgba(111,181,251,1) 37%)",
                webkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Delvex
            </span>
          </div>

          <h4 className="text-2xl font-semibold text-slate-700 mb-8">
            Sign In
          </h4>
          <div className="w-4/6 mb-6 flex justify-center">
            <TextField
              placeholder="john@gmail.com"
              type="email"
              label="Email"
              fullWidth
              size="small"
              variant="outlined"
              name="email"
              style={{
                width: "50%",
              }}
              autoFocus
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched?.email && errors?.email}
              helperText={touched?.email && errors?.email}
              required={true}
              disabled={values.submitting}
              value={values.email}
            />
          </div>
          <div className="w-4/6 mb-6 flex justify-center">
            <TextField
              type={showPass ? "text" : "password"}
              label="Password"
              size="small"
              required={true}
              variant="outlined"
              fullWidth
              style={{
                width: "50%",
              }}
              value={values.password}
              error={touched?.password && errors?.password}
              onBlur={handleBlur}
              helperText={touched?.password && errors?.password}
              name="password"
              disabled={values.submitting}
              onChange={handleChange}
              onKeyDown={(e) =>
                e.key === "Enter" && !authLoader && !values.submitting
                  ? handleSubmit(e)
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="cursor-pointer">
                    {showPass ? (
                      <Eye size={20} onClick={toggleShowPass} />
                    ) : (
                      <EyeOff size={20} onClick={toggleShowPass} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="w-4/6 flex justify-center mt-4">
            <Button
              variant="contained"
              fullWidth
              size="small"
              style={{
                background: "dodgerblue",
                padding: "8px 0",
                fontWeight: "600",
                borderRadius: ".9rem",
                width: "50%",
              }}
              data-aos-once={true}
              data-aos-offset={40}
              onClick={(e) =>
                !authLoader && !values.submitting && handleSubmit(e)
              }
            >
              {authLoader || values.submitting ? (
                <CircularProgress
                  size={20}
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                "SIGN IN"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginPageEnhanced = withFormik({
  mapPropsToValues: () => ({ email: "", password: "", submitting: false }),

  validationSchema: Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().min(8).max(40).required("Enter your password"),
  }),

  handleSubmit: async (values, { props, setFieldValue }) => {
    try {
      setFieldValue("submitting", true);
      const { email, password } = values;
      const { signin, history, showSnackbar } = props;
      const loggedIn = await signin({
        email,
        password,
      });
      if (loggedIn.success) {
        return history.replace("/app/tests");
      }
      showSnackbar({ type: "error", message: loggedIn?.error });
      setFieldValue("submitting", false);
    } catch (error) {
      setFieldValue("submitting", false);
    }
  },

  displayName: "LoginPageForm",
})(LoginPage);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signin: (payload) => dispatch(signin(payload)),
    setProfile: (token) => dispatch(setProfile(token)),
    showSnackbar: (options) => dispatch(showSnackbar(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageEnhanced);
