import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { connect } from "react-redux";

import { closeSnackbar } from "../store/actions/snackbar.actions";

const SnackbarComponent = (props) => {
  const { state, close } = props;

  const getColors = (severity) => {
    switch (severity) {
      case "success":
        return {
          backgroundColor: "#609966",
          color: "#fff",
        };
      case "error":
        return {
          backgroundColor: "#FDECEC",
          color: "#773E3E",
        };
      case "info":
        return {
          backgroundColor: "#FFF4E5",
          color: "#773E3E",
        };
      default:
        return {
          backgroundColor: "#FFF4E5",
          color: "#773E3E",
        };
    }
  };

  return (
    <Snackbar
      open={state.open}
      autoHideDuration={state.autoHideDuration}
      onClose={() => close()}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={Slide}
      style={{
        zIndex: 1600,
      }}
    >
      <Alert
        severity={state.type}
        sx={{ width: "100%", ...getColors(state.type), fontSize: "12px" }}
        size="small"
        icon={false}
        style={{
          fontSize: "12px",
          fontWeight: 600,
        }}
      >
        {state.message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state?.snackbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(closeSnackbar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);
