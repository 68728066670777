import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Button } from "@mui/material";
import { Plus } from "react-feather";

import MiniCard from "../components/miniCard";
import CategoryIcon from "@mui/icons-material/Category";
import {
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
} from "../store/actions/test.actions";
import DeleteDialog from "../components/deleteDialog";
import CategoryFormDialog from "../components/categoryFormDialog";

const useStyles = makeStyles((theme) => ({
  textBold: {
    // fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "31px",
    textAlign: "center",
    color: "#2D3958",
    margin: "16px 0px",
  },
  text1: {
    // fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
  },
  subText: {
    // fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    color: "#2D3958",
    margin: "8px 0px",
  },
  blockText: {
    // fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "31px",
  },
}));

const CategoriesPage = (props) => {
  const classes = useStyles();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [categories, setCategories] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState({
    name: null,
    error: null,
  });
  const [openCategory, setCategory] = useState({
    open: false,
    id: null,
    index: -1,
    details: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: null,
    index: -1,
    deleting: false,
  });

  const [addCatSubmission, setAddCatSubmission] = useState(false);

  const { getCategories, addCategory, updateCategory, deleteCategory } = props;

  const callApi = async () => {
    setFetching(true);
    const list = await getCategories();
    setCategories(list || []);
    setFetching(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  const closeCreateDialog = () => {
    setOpenCreateDialog(false);
    setForm({
      name: null,
      details: null,
      error: null,
    });
  };

  const addCategoryCall = async (values) => {
    setAddCatSubmission(true);
    if (!!openCategory?.id) {
      // update category call
      const updated = await updateCategory(openCategory?.id, values);
      if (updated) {
        // TODO: show snackbar
        const updatedList = [...categories];
        updatedList[openCategory?.index] = {
          _id: openCategory?.id,
          name: values?.name,
          details: values?.name,
        };
        setCategories(updatedList);
      }
    } else {
      // add category call
      const id = await addCategory(values);
      if (id) {
        setCategories((list) => [...list, { _id: id, ...values }]);
      }
      // TODO: "show snackbar"
    }
    setAddCatSubmission(false);
    toggleCategoryForm();
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm((state) => ({
      ...state,
      [name]: value,
      error: null,
    }));
  };

  const toggleDeleteDialog = async (id = null, index = -1) => {
    setDeleteDialog((state) => ({
      ...state,
      open: !!id,
      id,
      index,
      deleting: false,
    }));
  };

  const deleteCategoryHandler = async () => {
    setDeleteDialog((state) => ({
      ...state,
      deleting: true,
    }));
    const { id, index } = deleteDialog;
    // delete category request
    const deleted = await deleteCategory(id);
    if (deleted) {
      // TODO:  show success message
      const updatedList = [...categories];
      updatedList?.splice(index, 1);
      setCategories(updatedList);
      setDeleteDialog((state) => ({
        ...state,
        open: false,
        id: null,
        index: -1,
        deleting: false,
      }));
    } else {
      // TODO: show error
      setDeleteDialog((state) => ({
        ...state,
        open: false,
        id: null,
        index: -1,
        deleting: false,
      }));
    }
  };

  const toggleCategoryForm = (id, index = -1) => {
    setCategory((state) => ({
      open: !state?.open,
      id,
      index,
      details:
        !state?.open && !!id
          ? {
              name: categories[index]?.name,
              details: categories[index]?.details,
            }
          : null,
    }));
  };

  return (
    <Fragment>
      <DeleteDialog
        open={deleteDialog?.open}
        text={"Are you sure?"}
        handleClose={() => toggleDeleteDialog()}
        subText={
          "This is an irreversible operation. This category & all tests related to it will be deleted permanently."
        }
        onDelete={() => deleteCategoryHandler()}
        deleting={deleteDialog?.deleting}
      />
      <CategoryFormDialog
        open={openCategory?.open}
        toggle={toggleCategoryForm}
        addCategoryCall={addCategoryCall}
        addCatSubmission={addCatSubmission}
        details={openCategory?.details || null}
        update={!!openCategory?.id}
      />
      {fetching ? (
        <div className="flex w-full h-full justify-center items-center">
          <CircularProgress />
        </div>
      ) : !categories?.length ? (
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col w-full min-h-full justify-center items-center">
            {/* <img src="icons/lockGraphic.svg" alt="lock icon" /> */}
            <div className={classes.textBold}>No Category Found</div>
            <div className="flex w-full justify-center">
              <Button
                style={{
                  backgroundColor: "#7A4069",
                  color: "white",
                  borderRadius: "18px",
                  padding: "8px 18px",
                  textTransform: "capitalize",
                }}
                onClick={() => toggleCategoryForm()}
              >
                <span className="mr-2">
                  <CategoryIcon
                    style={{
                      color: "white",
                      fontSize: "1.3rem",
                    }}
                  />
                </span>
                Add Category
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col h-full p-4">
          <div className="flex w-full justify-between items-center px-5">
            <div className={classes.blockText}>Categories</div>
            <Button
              style={{
                backgroundColor: "#7A4069",
                color: "white",
                borderRadius: "18px",
                padding: "8px 18px",
                textTransform: "capitalize",
              }}
              onClick={() => toggleCategoryForm()}
            >
              <span className="mr-2">
                <Plus
                  size={20}
                  style={{
                    color: "white",
                    fontSize: "1.3rem",
                  }}
                />
              </span>
              Add Category
            </Button>
          </div>
          <div
            className="flex w-full h-full py-4 overflow-hidden"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignContent: "flex-start" /* NEW */,
              rowGap: "12px",
            }}
          >
            {categories.map((category, index) => (
              <MiniCard
                index={index}
                id={category._id}
                title={category.name}
                key={category._id}
                deleteKey
                clickHandler={() => toggleCategoryForm(category?._id, index)}
                toggleDeleteDialog={toggleDeleteDialog}
              />
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: () => dispatch(getCategories()),
    addCategory: (values) => dispatch(addCategory(values)),
    updateCategory: (id, values) => dispatch(updateCategory(id, values)),
    deleteCategory: (id) => dispatch(deleteCategory(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);
