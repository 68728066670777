import { OPEN, CLOSE } from "./actionTypes";

export const showSnackbar = (options) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OPEN,
        options,
      });
    } catch (error) {
      return error;
    }
  };
};

export const closeSnackbar = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLOSE,
      });
    } catch (error) {
      return error;
    }
  };
};
