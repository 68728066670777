import { TOGGLE_SIDEBAR } from "../actions/actionTypes";

export const INITIAL_STATE = {
  isSidebarOpened: true,
};

const layoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    default:
      return state;
  }
};

export default layoutReducer;
