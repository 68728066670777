import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Drawer,
  CircularProgress,
  Button,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {},
  messageBlock: {
    margin: "2rem",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "20vh",
    maxHeight: "20vh",
  },
  boldMessageText: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DrawerComponent = (props) => {
  const { show, toggle, onSubmit, getAllQuestions, getTags } = props;
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [importing, setImporting] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [fetchingTags, setFetchingTags] = useState(false);
  const [selectAllQuestions, setSelectAllQuestions] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!show) return; // so that we fetch questions when drawer gets opened only
    fetchTags();
    loadQuestions();
  }, [show]);

  useEffect(() => {
    loadQuestions();
  }, [selectedTags]);

  
  const fetchTags = async () => {
    setFetchingTags(true);
    const tags = await getTags();
    setFetchedTags(tags?.data || []);
    setFetchingTags(false);
  };

  const loadQuestions = async () => {
    setLoading(true);
    const resp = await getAllQuestions(selectedTags);
    setQuestions(resp || []);
    setLoading(false);
  };

  const handleQuestionSelect = (id) => {
    if (selectedQuestions.includes(id)) {
      setSelectedQuestions(selectedQuestions.filter((q) => q !== id));
    } else {
      setSelectedQuestions([...selectedQuestions, id]);
    }
  };

  const handleAddSelected = async () => {
    setImporting(true);
    await onSubmit(selectedQuestions);
    setSelectedQuestions([]);
    setQuestions([]);
    setImporting(false);
    setImporting(false);
  };

  
  const handleSelectAllQuestions = () => {
    if (selectAllQuestions) {
      setSelectedQuestions([]);
      setSelectAllQuestions(false);
    } else {
      const allQuestionIds = questions.map((question) => question._id);
      setSelectedQuestions(allQuestionIds);
      setSelectAllQuestions(true);
    }
  };

  const handleToggle = () => {
    setImporting(false);
    setSelectedQuestions([]);
    setQuestions([]);
    setSelectedTags([]);
    setFetchedTags([]);
    setFetchingTags(false);
    toggle();
  };

  const handleTagSelection = (event) => {
    setSelectedTags(event.target.value);
  };

  const getSelectedTagChipData = () => {
    return fetchedTags.filter((tag) => selectedTags.includes(tag._id));
  };

  const handleTagFilterDelete = (id) => {
    setSelectedTags((tags) => tags.filter((tag) => tag != id));
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={show}
      onClose={handleToggle}
      PaperProps={{
        style: {
          height: "80vh",
          minHeight: "80vh",
          maxHeight: "80vh",
          overflow: "hidden",
          paddingRight: "3vh"
        },
      }}
    >
      <div className="flex flex-col py-2 px-2 overflow-none h-full">
        <div
          style={{
            position: "sticky",
            top: "0",
            zIndex: 98,
            backgroundColor: "white",
            borderBottom: "1px solid #dadada",
            padding: "6px 4px",
          }}
          className="flex items-center"
        >
          <div className="flex flex-grow items-center">
            {!!questions.length && (
              <>
                <span>Tag Filter</span>
                <FormControl sx={{ m: 1, width: 250 }}>
                  {/* <InputLabel id="tag-filter">Tag</InputLabel> */}
                  <Select
                    labelId="tag-filter-selector"
                    id="tag-filter"
                    multiple
                    size="small"
                    placeholder="Tag filter"
                    value={selectedTags}
                    onChange={handleTagSelection}
                    input={<OutlinedInput placeholder="Tag filter" value="" />}
                    renderValue={() => ""}
                    MenuProps={MenuProps}
                  >
                    {fetchedTags.map((tag) => (
                      <MenuItem key={tag._id} value={tag._id}>
                        {tag.tag}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <span>
              {getSelectedTagChipData().map((tag) => (
                <Chip
                  key={tag._id}
                  label={tag.tag}
                  onClick={() => {}}
                  onDelete={() => handleTagFilterDelete(tag._id)}
                  style={{
                    marginRight: ".4rem",
                  }}
                />
              ))}
            </span>
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectAllQuestions}
              onChange={handleSelectAllQuestions}
              style={{ marginLeft: "1em", marginRight: "0.5em" }}
            />
            Select All
          </div>
          <h4 style={{ marginLeft: "1em" }}>
            Selected: <span>{selectedQuestions.length || 0}</span>
          </h4>
        </div>
        {loading ? (
          <div
            className={`flex w-full items-center justify-center`}
            style={{
              minHeight: "22rem",
            }}
          >
            <CircularProgress />
          </div>
        ) : questions.length ? (
          <div className="flex flex-col w-full h-full overflow-auto">
            <div
              className="flex flex-col py-2 my-1 overflow-auto"
              data-aos="fade-up"
              style={{
                height: "100%",
              }}
            >
              {questions.map((question) => (
                <div
                  className="flex align-center py-2 my-1 bg-slate-300 px-4 rounded-2"
                  key={question._id}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: question.question }}
                    className="flex-grow"
                  />
                  <input
                    type="checkbox"
                    checked={selectedQuestions.includes(question._id)}
                    onChange={() => handleQuestionSelect(question._id)}
                    className="cursor-pointer"
                  />
                </div>
              ))}
            </div>
            <div
              style={{
                position: "sticky",
                bottom: "0",
                backgroundColor: "white",
                padding: "10px",
                display: "flex",
                maxHeight: "4rem",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={handleToggle}
                style={{
                  textTransform: "capitalize",
                  fontSize: ".9rem",
                  color: "gray",
                  fontWeight: 700,
                  backgroundColor: "rgb(226 232 240)",
                  marginRight: ".4rem",
                }}
                size="small"
                disabled={importing}
              >
                Close
              </Button>
              <Button
                onClick={() => !importing && handleAddSelected()}
                variant="contained"
                size="small"
                style={{
                  backgroundColor: selectedQuestions?.length
                    ? "dodgerblue"
                    : "rgb(226 232 240)",
                  color: selectedQuestions?.length ? "white" : "gray",
                  fontSize: ".9rem",
                  textTransform: "capitalize",
                }}
                disabled={!!!selectedQuestions.length}
              >
                {importing ? (
                  <CircularProgress
                    size={22}
                    style={{
                      padding: ".2rem",
                      color: "white",
                    }}
                  />
                ) : (
                  "Add Selected"
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className={classes.messageBlock}>
            <h4 className={classes.boldMessageText}>No Questions Found</h4>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
