import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// components
import Layout from "./containers/layout";
import Snackbar from "./components/snackbar";

//pages
import NotFoundPage from "./containers/notFoundPage";
import LoginPage from "./containers/loginPage";
import AOS from "aos";
import "aos/dist/aos.css";
import { setProfile } from "./store/actions/auth.actions";
import {
  toggleQuestionDialog,
  toggleTagDialog,
} from "./store/actions/dialog.actions";
import UpsertQuestionForm from "./components/upsertQuestionDialog";
import { addQuestion } from "./store/actions/test.actions";
import {
  getTags,
  createTag,
  deleteTag,
  updateTag,
} from "./store/actions/tag.actions";
import TagDialog from "./components/tagDialog";
import { showSnackbar } from "./store/actions/snackbar.actions";

const App = (props) => {
  const {
    setProfile,
    toggleQuestionDialog,
    isAuthenticated,
    showQuestionDialog,
    showTagDialog,
    addQuestion,
    getTags,
    toggleTagDialog,
    createTag,
    deleteTag,
    updateTag,
    showSnackbar,
  } = props;

  const history = createBrowserHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) authenticateUser(token);
    else setLoading(false);
  }, []);

  const authenticateUser = async (token) => {
    await setProfile(token);
    setLoading(false);
  };

  const addQuestionCall = async (values, html) => {
    // const { question, options, correct } = values;
    // add call
    const id = await addQuestion(null, null, values);
    if (id) {
      // show success snackbar
    } else {
      // show error snackbar
    }
    toggleQuestionDialog();
  };

  if (loading)
    return (
      <div className="flex w-screen h-screen justify-center items-center">
        <CircularProgress size={32} />
      </div>
    );

  return (
    <Router history={history}>
      <Snackbar />
      <TagDialog
        open={showTagDialog}
        toggle={toggleTagDialog}
        createTag={createTag}
        getTags={getTags}
        deleteTag={deleteTag}
        updateTag={updateTag}
        showSnackbar={showSnackbar}
      />
      <UpsertQuestionForm
        open={showQuestionDialog}
        toggle={toggleQuestionDialog}
        update={false}
        addQuestionCall={addQuestionCall}
        details={{}}
        getTags={getTags}
        showSnackbar={showSnackbar}
      />
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Redirect to={isAuthenticated ? "/app/tests" : "/login"} />
          )}
        />
        <Route exact path="/app" render={() => <Redirect to="/app/tests" />} />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          path="/app"
          component={Layout}
        />
        <PublicRoute
          exact
          path="/login"
          isAuthenticated={isAuthenticated}
          component={LoginPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  return isAuthenticated ? (
    <Route>
      <Redirect to="/app/tests" />
    </Route>
  ) : (
    <Component {...rest} />
  );
};

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Route>
      <Redirect to="/login" />
    </Route>
  );
};

const mapStateToProps = (state) => {
  const { auth, dialog } = state;
  return {
    isAuthenticated: auth.isAuthenticated,
    showQuestionDialog: dialog.showQuestionDialog,
    showTagDialog: dialog.showTagDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setProfile,
      toggleQuestionDialog,
      addQuestion,
      getTags,
      createTag,
      deleteTag,
      updateTag,
      toggleTagDialog,
      showSnackbar,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
