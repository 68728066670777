import React from "react";
import { Paper, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

//components
import { Typography } from "@mui/material";

// styles
const useStyles = makeStyles((theme) => ({
  widgetWrapper: {
    display: "flex",
  },
  inheritHeight: {
    minHeight: "auto",
  },
  widgetHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
  },
  widgetBody: {
    height: (props) => (props.fullHeight ? "100%" : "inherit"),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "rgba(255, 255, 255, 0.35)",
    },
  },
  searchIcon: {
    color: "rgba(0, 0, 0, 0.23)",
  },
  topPart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 24,
  },
  formControl: {
    margin: 0,
    minWidth: 65,
    "& > div > svg": {
      right: "2px",
    },
  },
  inputPosition: {
    transform: "translate(7px, 10px) scale(1)",
    fontSize: 15,
  },
  selectPadding: {
    padding: 7,
    "& > svg": {
      right: 0,
    },
  },
}));

const Widget = ({
  children,
  title,
  subtitle,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  inheritHeight,
  searchField,
  className,
  showMenu,
  style,
  widgetWithDropdown,
  ...props
}) => {
  let classes = useStyles(props);

  return (
    <div
      className={`
          ${inheritHeight ? classes.inheritHeight : ""}
          ${!inheritHeight ? classes.widgetWrapper : ""} 
          ${className ? className : ""}
      `}
      style={style}
    >
      <Paper
        className={`${classes.paper}
          ${props.paperClass ? props.paperClass : ""}
        `}
        classes={{ root: classes.widgetRoot }}
      >
        {!title ? (
          <>
            {header ? (
              <div className={classes.widgetHeader}>{header}</div>
            ) : null}
          </>
        ) : (
          <>
            {!widgetWithDropdown && (
              <div className={classes.widgetHeader}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Box display={"flex"} style={{ width: "calc(100% - 20px)" }}>
                    <Typography
                      variant="h4"
                      color="text"
                      colorBrightness={"secondary"}
                      noWrap
                    >
                      {title}
                    </Typography>
                    <Box alignSelf="flex-end" ml={1}>
                      <Typography
                        color="text"
                        colorBrightness={"hint"}
                        variant={"caption"}
                      >
                        {subtitle}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
            )}
          </>
        )}

        <div
          className={`${classes.widgetBody}
            ${noBodyPadding ? classes.noPadding : ""}
            ${!title && !noBodyPadding ? classes.paddingTop : ""}
            ${bodyClass ? bodyClass : ""}
          `}
        >
          {children}
        </div>
      </Paper>
    </div>
  );
};

export default Widget;
