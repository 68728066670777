import React, { useEffect } from "react";
import DialogComponent from "./dialogComponent";
import { TextField } from "@mui/material";
import { withFormik } from "formik";
import * as Yup from "yup";

const CategoryFormDialog = (props) => {
  const {
    handleBlur,
    handleChange,
    touched,
    errors,
    handleSubmit,
    values,
    setValues,
    resetForm,
    open,
    toggle,
    addCatSubmission,
    details,
    update,
  } = props;

  useEffect(() => {
    setValues({
      ...values,
      name: details?.name || "",
      details: details?.details || values?.details,
    });
  }, [details]);

  const toggleDialog = () => {
    resetForm();
    toggle();
  };

  return (
    <DialogComponent
      title={update ? "Update Test Category" : "Create New Test Category"}
      open={open}
      handleClose={toggleDialog}
      handleSubmit={handleSubmit}
      saveText={update ? "Update" : "Create"}
      saving={addCatSubmission}
    >
      <TextField
        id="name"
        label="Category name"
        error={touched.name && !!errors.name}
        disabled={values.submitting}
        fullWidth
        helperText={touched.name && errors.name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
        name="name"
        size={"small"}
        style={{ marginTop: "1rem" }}
        autoFocus
      />
      <TextField
        id="details"
        label="Description"
        error={touched.details && !!errors.details}
        disabled={values.submitting}
        fullWidth
        helperText={touched.details && errors.details}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.details}
        name="details"
        size={"small"}
        multiline
        rows={4}
        style={{ marginTop: "1rem" }}
      />
    </DialogComponent>
  );
};

const CategoryForm = withFormik({
  mapPropsToValues: () => ({
    name: "",
    details: "",
    submitting: false,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required("Required"),
    details: Yup.string().required("Required"),
  }),

  handleSubmit: async (
    values,
    { resetForm, setSubmitting, setValues, props, ...formikProps }
  ) => {
    try {
      const { addCategoryCall, update } = props;
      setValues({ ...values, submitting: true });
      await addCategoryCall(values);
      resetForm();
      setValues({ ...values, submitting: false });
    } catch (error) {
      setValues({ ...values, submitting: false });
      setSubmitting(false);
    }
  },

  displayName: "CategoryForm",
})(CategoryFormDialog);

export default CategoryForm;
