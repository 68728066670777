import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";
import Lottie from "react-lottie";
import notFoundData from "../utilities/data/not_found_lottie.json";

const useStyles = makeStyles((theme) => ({
  text1: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "17px",
    lineHeight: "34px",
    textAlign: "center",
    letterSpacing: "4px",
    textTransform: "uppercase",
    color: "black",
    marginBottom: "1rem",
  },
  btnText: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "black",
    cursor: "pointer",
    background: "#cacaca",
    padding: "10px 14px",
    borderRadius: "8px",
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col w-screen h-screen overflow-none items-center justify-center">
      <Lottie
        options={defaultOptions}
        style={{
          width: "100vw",
          height: "100vh",
        }}
      />
      <div className="flex flex-col w-full h-1/2 justify-end items-center pb-40 fixed bottom-32">
        <div className={classes.text1}>Oops! page not found</div>
        <div className="flex w-full justify-center">
          <div
            customStyles={{
              padding: "11px 16px",
              width: "139px",
              height: "40px",
            }}
            onClick={() => history.push("/")}
          >
            <span className={classes.btnText}>back to home</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
