import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  MenuItem,
  SwipeableDrawer,
  TextField,
  InputAdornment,
} from "@mui/material";
import { withFormik } from "formik";
import * as Yup from "yup";
import { Plus, X } from "react-feather";

const TestFormDialog = (props) => {
  const {
    handleBlur,
    handleChange,
    touched,
    errors,
    handleSubmit,
    values,
    setValues,
    resetForm,
    open,
    toggle,
    categories,
    toggleCategoryForm,
    initializeWith,
    mode = "add",
    toggleDeleteDialog,
  } = props;

  useEffect(() => {
    if (initializeWith && mode === "update") {
      setValues({
        ...values,
        ...initializeWith,
        category:
          typeof initializeWith?.category === "string"
            ? initializeWith?.category
            : initializeWith?.category?._id,
      });
    }
  }, [mode]);

  const toggleDrawer = () => {
    resetForm();
    toggle();
  };

  const addLevelClick = async () => {
    setValues({
      ...values,
      levels: [
        ...values.levels,
        { name: "", min_criteria: 1, score: 0, duration: 30 },
      ],
    });
  };

  const removeLevel = (index) => {
    const updatedLevels = [...values.levels];
    updatedLevels.splice(index, 1);
    setValues({ ...values, levels: updatedLevels });
  };

  const handleLevelFieldChange = (name, value, index) => {
    const updatedLevels = [...values.levels];
    updatedLevels[index] = {
      ...updatedLevels[index],
      [name]: value,
    };
    setValues({ ...values, levels: updatedLevels });
  };

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={open}
      onClose={() => toggleDrawer()}
      onOpen={() => toggleDrawer()}
      style={{
        maxWidth: "40vw",
      }}
    >
      <div className="flex flex-col w-full h-screen">
        <div
          className="flex w-full bg-slate-100 p-2 items-center overflow-hidden"
          style={{
            paddingLeft: "1.5rem",
            width: "40vw",
          }}
        >
          <h4
            className="font-semibold text-slate-800"
            style={{
              fontSize: "1.2rem",
            }}
          >
            {mode === "update" ? "Update" : "Create"} Test
          </h4>
        </div>
        <div
          className="flex flex-col h-full p-6 flex-1 overflow-auto"
          style={{
            width: "40vw",
          }}
        >
          <TextField
            id="name"
            label="Test Name"
            error={touched.name && !!errors.name}
            disabled={values.submitting}
            fullWidth
            helperText={touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            name="name"
            size={"small"}
            style={{ marginTop: "1rem" }}
            autoFocus
          />
          <TextField
            select
            id="category"
            label="Category"
            error={touched.category && !!errors.category}
            disabled={values.submitting}
            fullWidth
            helperText={touched.category && errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.category}
            name="category"
            size={"small"}
            style={{ marginTop: "1rem", zIndex: 6000 }}
          >
            {categories?.map((category) => (
              <MenuItem value={category?._id} key={category?._id}>
                {category?.name}
              </MenuItem>
            ))}
          </TextField>
          <h4
            style={{
              padding: "8px 3px",
              fontWeight: 700,
              fontSize: "14px",
              color: "#555",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            Category not in list?{" "}
            <span
              style={{
                cursor: "pointer",
                color: "dodgerblue",
                paddingLeft: ".4rem",
              }}
              onClick={() => toggleCategoryForm()}
            >
              Add new category
            </span>
          </h4>
          <TextField
            id="details"
            label="Description"
            error={touched.details && !!errors.details}
            disabled={values.submitting}
            fullWidth
            helperText={touched.details && errors.details}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.details}
            name="details"
            size={"small"}
            multiline
            rows={4}
            style={{ marginTop: "1rem" }}
          />

          <div className="flex w-full mt-4 ml-3 justify-between">
            <div className="text-16 text-slate-600 font-semibold">
              Levels (<span>{values?.levels?.length || 0}</span>)
            </div>
            <h4
              style={{
                fontWeight: 700,
                fontSize: "14px",
                color: "#555",
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
                paddingRight: "1rem",
              }}
              onClick={() => addLevelClick()}
            >
              <Plus
                size={16}
                style={{
                  color: "dodgerblue",
                }}
              />
              <span
                style={{
                  color: "dodgerblue",
                  paddingLeft: ".2rem",
                }}
              >
                Add Level
              </span>
            </h4>
          </div>
          {mode === "update" && (
            <div className="flex w-full text-12 text-slate-600 ml-3 my-1 font-semibold flex-wrap">
              In case of removal of any level, all questions of that level will
              be deleted permanently. (this operation is irreversible)
            </div>
          )}
          <div className="flex flex-col w-full mb-2 overflow-auto">
            {values?.levels?.map((level, index) => (
              <div
                className={`flex flex-col bg-slate-50 border-1 border-slate-100 rounded-lg p-4 my-2 pt-6 relative hover:shadow-sm transition-all ease-in-out`}
                key={index}
              >
                <div className="absolute right-2 top-2">
                  <X
                    size={28}
                    className="cursor-pointer p-2 rounded-2xl text-black"
                    onClick={() => removeLevel(index)}
                  />
                </div>
                <TextField
                  id={`name-${index}`}
                  label="Level Name"
                  fullWidth
                  onChange={(e) =>
                    handleLevelFieldChange("name", e.target.value, index)
                  }
                  value={values.levels[index].name}
                  name={`name-${index}`}
                  size={"small"}
                  style={{ marginTop: "1rem" }}
                />
                <div className="flex w-full mt-4">
                  <TextField
                    type="number"
                    id={`score-${index}`}
                    label="Score"
                    fullWidth
                    onChange={(e) =>
                      handleLevelFieldChange("score", e.target.value, index)
                    }
                    value={values.levels[index].score}
                    name={`score-${index}`}
                    size={"small"}
                    style={{ marginRight: ".6rem" }}
                  />
                  <TextField
                    type="number"
                    id={`duration-${index}`}
                    label="Duration"
                    fullWidth
                    onChange={(e) =>
                      handleLevelFieldChange("duration", e.target.value, index)
                    }
                    value={values.levels[index].duration}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">min</InputAdornment>
                      ),
                    }}
                    name={`duration-${index}`}
                    size={"small"}
                  />
                </div>
                <TextField
                  type="number"
                  id={`min_criteria-${index}`}
                  label="Minimum question criteria"
                  error={touched.min_criteria && !!errors.min_criteria}
                  helperText="minimum this many Qs must be correct"
                  disabled={values.submitting}
                  fullWidth
                  onChange={(e) =>
                    handleLevelFieldChange(
                      "min_criteria",
                      e.target.value,
                      index
                    )
                  }
                  value={values.levels[index].min_criteria}
                  name={`min_criteria-${index}`}
                  size={"small"}
                  style={{
                    marginTop: "1rem",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex w-full justify-center items-center py-2 bg-slate-100 self-end">
          <Button
            onClick={toggleDrawer}
            variant="outlined"
            style={{
              textTransform: "capitalize",
              background: "white",
              fontSize: ".9rem",
              color: "gray",
              border: "1px solid #aaa",
              marginRight: ".8rem",
              fontWeight: 700,
            }}
            size="small"
            disabled={values?.submitting}
          >
            Close
          </Button>
          <Button
            onClick={(event) => !values?.submitting && handleSubmit(event)}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "dodgerblue",
              color: "white",
              fontSize: ".9rem",
              textTransform: "capitalize",
            }}
          >
            {values?.submitting ? (
              <CircularProgress
                size={22}
                style={{
                  padding: ".2rem",
                  color: "white",
                }}
              />
            ) : mode === "update" ? (
              "Update"
            ) : (
              "Create"
            )}
          </Button>
          {mode === "update" && (
            <Button
              onClick={(event) => !values?.submitting && toggleDeleteDialog()}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#FF1E00",
                color: "white",
                fontSize: ".9rem",
                textTransform: "capitalize",
                marginLeft: ".6rem",
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </SwipeableDrawer>
  );
};

const TestForm = withFormik({
  mapPropsToValues: () => ({
    name: "",
    details: "",
    category: "",
    levels: [{ name: "level1", score: 100, min_criteria: 1, duration: 30 }],
    submitting: false,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required("Required"),
    details: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
  }),

  handleSubmit: async (
    values,
    { resetForm, setSubmitting, setValues, props, ...formikProps }
  ) => {
    try {
      setValues({ ...values, submitting: true });
      const levels = values.levels?.filter(
        (level) => level?.name?.trim()?.length
      );
      await props.addTest({ ...values, levels });
      resetForm();
      setValues({
        ...values,
        submitting: false,
        name: "",
        details: "",
        category: "",
      });
      props.toggle();
    } catch (error) {
      setValues({ ...values, submitting: false });
      setSubmitting(false);
      props.toggle();
      return false;
    }
  },

  displayName: "AddTestForm",
})(TestFormDialog);

export default TestForm;
