import { ADD_TEST, ADD_CATEGORY, UPDATE_CATEGORY } from "./actionTypes";
import axios from "../../utilities/axios";

export const getTests = (values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const added = await axios.get("/test", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return added.data?.tests;
    } catch (error) {
      return false;
    }
  };
};

export const getTestDetails = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const test = await axios.get("/test/" + id, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return test?.data;
    } catch (error) {
      return false;
    }
  };
};

export const addTest = (values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const added = await axios.post(
        "/test",
        {
          name: values?.name,
          details: values?.details,
          category: values?.category,
          levels: values?.levels,
          duration: values?.duration,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return added.data;
    } catch (error) {
      return false;
    }
  };
};

export const updateTest = (id, values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const updated = await axios.post(
        "/test/update/" + id,
        {
          name: values?.name,
          details: values?.details,
          category: values?.category,
          levels: values?.levels,
          duration: values?.duration,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return updated?.status === 200;
    } catch (error) {
      return false;
    }
  };
};

export const addCategory = (values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const added = await axios.post(
        "/category",
        {
          name: values?.name,
          details: values?.details,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return added.data;
    } catch (error) {
      return false;
    }
  };
};

export const getCategories = (values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const categories = await axios.get("/category", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return categories?.data?.categories;
    } catch (error) {
      return error;
    }
  };
};

export const updateCategory = (id, values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const updated = await axios.post(
        "/category/update/" + id,
        {
          name: values?.name,
          details: values?.details,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return updated?.status === 200;
    } catch (error) {
      return false;
    }
  };
};

export const deleteCategory = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const deleted = await axios.post(
        "/category/delete/" + id,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const deleteTest = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const deleted = await axios.post(
        "/test/delete/" + id,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const getAllQuestions = (exclude, tags = []) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const questions = await axios.get(
        `/questions/?exclude=${exclude}&tags=${JSON.stringify(tags)}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return questions?.data || [];
    } catch (error) {
      return false;
    }
  };
};

export const getQuestions = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const questions = await axios.get("/test/q/all/" + id, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return questions?.data || [];
    } catch (error) {
      return false;
    }
  };
};

export const importQuestions = (testId, levelId, questionIds) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "/test/q/import",
        {
          testId,
          levelId,
          questionIds,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const addQuestion = (testId, levelId, values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const added = await axios.post(
        "/test/q/add",
        {
          question: values?.question,
          options: values?.options,
          testId,
          levelId,
          correct: values?.correct,
          tags: values?.tags || [],
          msq: values?.msq,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return added.data;
    } catch (error) {
      return false;
    }
  };
};

export const updateQuestion = (id, values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const updated = await axios.post(
        "/test/q/update/" + id,
        {
          question: values?.question,
          options: values?.options,
          levelId: values?.levelId,
          correct: values?.correct,
          tags: values?.tags,
          msq: values?.msq,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return updated?.status === 200;
    } catch (error) {
      return false;
    }
  };
};

export const deleteQuestion = (id, levelId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const deleted = await axios.post(
        "/test/q/remove",
        {
          qid: id,
          levelId,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return deleted?.status === 200;
    } catch (error) {
      return false;
    }
  };
};

export const reorderQuestions = (levelId, list) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const deleted = await axios.post(
        "/test/q/reorder",
        {
          levelId,
          list,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return deleted?.status === 200;
    } catch (error) {
      return false;
    }
  };
};
