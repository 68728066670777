import React, { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import { makeStyles } from "@mui/styles";
import { EditorState, convertToRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import {
  InputAdornment,
  TextField,
  Tooltip,
  Autocomplete,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { withFormik } from "formik";
import { Check, X } from "react-feather";
import * as Yup from "yup";

import Editor from "./Editor";
import DialogComponent from "./dialogComponent";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "6px",
    paddingTop: "4px",
    justifyContent: "flex-end",
  },
  link_text: {
    fontSize: "0.9rem",
    color: "#3C68FA",
    cursor: "pointer",
    transition: "all ease-in-out .1s",
    "&:hover": {
      fontWeight: "bold",
    },
  },
  link_disabled: {
    fontWeight: "normal !important",
    cursor: "default !important",
    color: "#aaa !important",
  },
}));

const UpsertQuestionDialog = (props) => {
  const {
    handleBlur,
    handleChange,
    touched,
    errors,
    values,
    setValues,
    resetForm,
    handleSubmit,
    open,
    toggle,
    update,
    details,
    getTags,
  } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedTags, setSelectedTags] = useState([]);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [fetchingTags, setFetchingTags] = useState(false);
  const [MCQ, setMCQ] = useState(true);

  const setInitialStates = () => {
    setEditorState(EditorState.createEmpty());
    setSelectedTags([]);
    setFetchedTags(false);
  };

  useEffect(() => {
    const initialStateSetCall = async () => {
      if (open) setLoading(true);
      setInitialStates();
      await fetchTags();
      if (update) {
        setEditorState(
          EditorState.createWithContent(stateFromHTML(details?.question))
        );
        setSelectedTags(details?.tags || []);
      }
      setLoading(false);
    };
    initialStateSetCall();
  }, [open]);

  useEffect(() => {
    setValues({
      ...values,
      options: details?.options || values?.options,
      correct: details?.correct || values?.correct,
    });
    setMCQ(update ? !details?.msq : true);
  }, [details]);

  const handleTagSelection = (event, value) => {
    setSelectedTags(value);
  };

  const fetchTags = async () => {
    setFetchingTags(true);
    const tags = await getTags();
    setFetchedTags(tags?.data || []);
    setFetchingTags(false);
  };

  const addNewOption = () => {
    if (values.options.length >= 6) return;
    const options = [...values.options, { text: "" }];
    setValues({ ...values, options });
  };

  const toggleDialog = () => {
    setEditorState(EditorState.createEmpty());
    resetForm();
    toggle();
  };

  const handleOptionChange = (e) => {
    const { value, name } = e.target;
    const index = +`${name.replace("option-", "")}`;
    const updatedOptions = [...values?.options];
    updatedOptions[index] = {
      text: value,
    };
    setValues({ ...values, options: updatedOptions });
  };

  const removeOptionAt = (index) => {
    const options = [...values.options];
    options?.splice(index, 1);
    setValues({
      ...values,
      correct: values.correct
        ?.filter((value) => value != index + 1)
        .map((value) => {
          if (value > index + 1) return value - 1;
          return value;
        }),
      options,
    });
  };

  if (loading) {
    return (
      <div class="fixed top-0 left-0 bottom-0 right-0 bg-white bg-opacity-20 flex justify-center items-center z-50 transition-all ease-in-out duration-200">
        <LinearProgress style={{ width: "22%", zIndex: 1003 }} />
      </div>
    );
  }

  return (
    <DialogComponent
      title={`${update ? "Update" : "Add"} Question`}
      open={open}
      handleClose={toggleDialog}
      handleSubmit={(values, formikProps) => {
        const currentState = editorState.getCurrentContent();
        if (!currentState.hasText()) {
          setValues((values) => ({ ...values, error: "Enter Question" }));
          return;
        }

        const rawContentState = convertToRaw(currentState);
        const markup = draftToHtml(rawContentState);

        // Create a temporary element to hold the markup
        var tempEl = document.createElement("div");
        tempEl.innerHTML = markup;

        // Wrap the <pre> blocks in <code> blocks
        var preTags = tempEl.getElementsByTagName("pre");
        for (var i = 0; i < preTags.length; i++) {
          // Check if the current <pre> block is the first in a group
          if (
            i === 0 ||
            (preTags[i].previousSibling &&
              preTags[i].previousSibling.nodeType === 1 &&
              preTags[i].previousSibling.tagName === "PRE")
          ) {
            var codeTag = document.createElement("code");
            preTags[i].parentNode.insertBefore(codeTag, preTags[i]);
          }
          // Move the current <pre> block inside the current <code> block
          codeTag.appendChild(preTags[i]);
        }

        // Get the updated markup from the temporary element
        var html = tempEl.innerHTML;

        setValues((values) => ({
          ...values,
          html,
          tags: selectedTags,
          mcq: MCQ,
        }));
        handleSubmit();
      }}
      saveText={update ? "Update" : "Add"}
      saving={values?.submitting}
    >
      {values?.error ? (
        <div>
          <h4
            style={{
              color: "#da5252",
              fontSize: "12px",
              padding: ".2rem 0",
            }}
          >
            {values?.error}
          </h4>
        </div>
      ) : (
        <div />
      )}
      <Editor editorState={editorState} setEditorState={setEditorState} />
      <Autocomplete
        multiple
        id="tags-outlined"
        className="mt-4"
        size="small"
        options={fetchedTags || []}
        onChange={handleTagSelection}
        disabled={fetchingTags}
        value={selectedTags || []}
        isOptionEqualToValue={(option, value) => option._id == value._id}
        getOptionLabel={(option) => option.tag}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Tags"
            placeholder="Type tag name here..."
          />
        )}
      />
      <div className={`flex items-center justify-between w-full mt-2`}>
        <ToggleButtonGroup
          color="primary"
          value={MCQ}
          exclusive
          onChange={(e) => {
            const value = e.target.value === "true";
            if (MCQ === false && value === true)
              setValues((values) => ({ ...values, correct: [] }));
            setMCQ(value === true);
          }}
          aria-label="Platform"
          style={{
            marginRight: ".4rem",
          }}
        >
          <ToggleButton
            style={{
              fontSize: ".8rem",
              padding: ".2rem .4rem",
              margin: 0,
            }}
            value={true}
          >
            MCQ
          </ToggleButton>
          <ToggleButton
            style={{
              fontSize: ".8rem",
              padding: ".2rem .4rem",
              margin: 0,
            }}
            value={false}
          >
            MSQ
          </ToggleButton>
        </ToggleButtonGroup>
        <h4
          className={`${classes.link_text} ${
            values.options?.length >= 6 ? classes.link_disabled : ""
          }`}
          onClick={() => addNewOption()}
        >
          Add option
        </h4>
      </div>
      {values?.options &&
        values?.options?.map((option, index) => (
          <TextField
            key={index}
            id={`option-${index}`}
            error={
              touched?.options?.length >= 4 &&
              errors?.options?.length >= 4 &&
              !!errors?.options[index]?.text
            }
            disabled={values.submitting}
            fullWidth
            helperText={
              touched?.options?.length >= 4 && errors?.options?.length >= 4
                ? errors?.options[index]?.text
                : ""
            }
            onChange={handleOptionChange}
            value={values.options[index]?.text}
            name={`option-${index}`}
            size={"small"}
            style={{
              marginTop: "1rem",
              background: values?.correct?.includes(index + 1) ? "#CEE5D0" : "",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span
                    style={{
                      fontWeight: 700,
                      color: "#888",
                      fontSize: "14px",
                    }}
                  >
                    {String.fromCharCode(65 + index)}
                  </span>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="mark as correct answer">
                    <Check
                      size={18}
                      onClick={() => {
                        if (MCQ) {
                          setValues({ ...values, correct: [index + 1] });
                        } else {
                          setValues({ ...values, correct: [index + 1] });
                          if (values?.correct?.includes(index + 1)) {
                            setValues({
                              ...values,
                              correct: values?.correct?.filter(
                                (val) => val !== index + 1
                              ),
                            });
                          } else {
                            setValues({
                              ...values,
                              correct: [...values?.correct, index + 1],
                            });
                          }
                        }
                      }}
                      color={
                        values?.correct?.includes(index + 1)
                          ? "#76BA99"
                          : "#888"
                      }
                      style={{
                        fontWeight: 700,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="remove option">
                    <X
                      size={18}
                      onClick={() => {
                        if (values.options.length <= 2) return;
                        removeOptionAt(index);
                      }}
                      color={values.options.length > 2 ? "red" : "#888"}
                      style={{
                        marginLeft: ".4rem",
                        color: values.options.length > 2 ? "red" : "grey",
                        fontWeight: 700,
                        cursor:
                          values.options.length > 2 ? "pointer" : "default",
                      }}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        ))}
    </DialogComponent>
  );
};

const UpsertQuestionForm = withFormik({
  mapPropsToValues: () => ({
    options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
    correct: [],
    tags: [],
    error: null,
    submitting: false,
    html: "",
    mcq: true,
  }),

  validationSchema: Yup.object().shape({
    options: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Field cannot be empty"),
      })
    ),
  }),

  handleSubmit: async (
    values,
    { setSubmitting, setValues, resetForm, props }
  ) => {
    try {
      let { options, tags, mcq } = values;
      let optionEmptyErr = false;
      if (!values.html?.length)
        return setValues({ ...values, error: "Enter Question" });
      options = options?.map((option) => {
        const text = option?.text?.toLowerCase()?.trim();
        if (!text?.length) optionEmptyErr = true;
        return {
          text,
        };
      });
      if (optionEmptyErr)
        return setValues({ ...values, error: "Option must not be empty" });
      if (!values?.correct?.length) {
        return setValues({ ...values, error: "Select correct option" });
      } else {
        setValues({ ...values, error: null, submitting: true });
        await props?.addQuestionCall({
          ...values,
          question: values.html,
          mcq,
        });
        setValues({
          ...values,
          error: null,
          submitting: false,
          html: "",
          mcq: true,
        });
        resetForm();
      }
    } catch (error) {
      setValues({ ...values, submitting: false });
      // TODO: show snackbar
      setSubmitting(false);
    }
  },

  displayName: "CategoryForm",
})(UpsertQuestionDialog);

export default UpsertQuestionForm;
