import React from "react";
import QuizIcon from "@mui/icons-material/Quiz";
import CategoryIcon from "@mui/icons-material/Category";
import { LogOut, HelpCircle, Tag } from "react-feather";

const structure = [
  { id: 0, label: "Tests", link: "/app/tests", icon: <QuizIcon /> },
  {
    id: 1,
    label: "Categories",
    link: "/app/categories",
    icon: <CategoryIcon />,
  },
  {
    id: 2,
    label: "Add Question",
    type: "button",
    icon: <HelpCircle />,
  },
  {
    id: 3,
    label: "Tag",
    type: "button",
    icon: <Tag />,
  },
  {
    id: 4,
    label: "Logout",
    type: "button",
    icon: <LogOut color="#B23B3B" size={22} />,
  },
];

export default structure;
