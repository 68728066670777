import { combineReducers } from "redux";

import auth from "./auth.reducer";
import preferenceReducer from "./preference.reducer";
import snackbarReducer from "./snackbar.reducer";
import layoutReducer from "./layout.reducer";
import dialogReducer from "./dialog.reducer";

const rootReducer = combineReducers({
  auth,
  snackbar: snackbarReducer,
  preferences: preferenceReducer,
  layout: layoutReducer,
  dialog: dialogReducer,
});

export default rootReducer;
