import React, { useEffect, useState, useRef, Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress, MenuItem, TextField } from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";

import {
  getQuestions,
  getTestDetails,
  reorderQuestions,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  getAllQuestions,
  importQuestions,
} from "../store/actions/test.actions";

import QuestionAccordion from "../components/question";
import UpsertQuestionForm from "../components/upsertQuestionDialog";
import DeleteDialog from "../components/deleteDialog";
import QuestionDrawer from "../components/DrawerComponent";
import { getTags } from "../store/actions/tag.actions";

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const TestDetailPage = (props) => {
  const {
    getTestDetails,
    getQuestions,
    reorderQuestions,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    getAllQuestions,
    importQuestions,
    getTags,
  } = props;

  const styles = useStyles();
  const listRef = useRef(null);
  const [showQuestionDrawer, setShowQuestionDrawer] = useState(false);
  const [expanded, setExpanded] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [fetchingQs, setFetchingQs] = useState(false);
  const [details, setDetails] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const [questions, setQuestions] = useState(null);
  const [isListDirty, setIsListDirty] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: null,
    index: -1,
    deleting: false,
  });

  const [upsertDialog, setUpsertDialog] = useState({
    open: false,
    id: null,
    question: null,
    options: null,
    correct: null,
    tags: [],
    index: -1,
    msq: false,
  });

  const locationState = props.location?.state;

  const callApi = async () => {
    const id = props.match?.params?.id;
    setLoading(true);
    const testDetails = await getTestDetails(id);
    setDetails(testDetails);
    if (testDetails?.levels?.length) {
      const level = testDetails?.levels[0];
      setSelectedLevel(level);
      // await fetchLevelQuestions(level._id);
    }
    setLoading(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    fetchLevelQuestions(selectedLevel?._id);
  }, [selectedLevel?._id]);

  const fetchLevelQuestions = async (id) => {
    if (!id) return;
    setFetchingQs(true);
    const questionList = (await getQuestions(id)) || [];
    listRef.current = questionList;
    setQuestions(questionList);
    setFetchingQs(false);
  };

  const toggleAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    if (startIndex === endIndex) return list;
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setIsListDirty(true);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reordered = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    setQuestions(reordered);
  };

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    // padding: grid,
    // width: 250,
  });

  const toggleDeleteDialog = async (id = null, index = -1) => {
    setDeleteDialog((state) => ({
      ...state,
      open: !!id,
      id,
      index,
      deleting: false,
    }));
  };

  const deleteQuestionCall = async () => {
    setDeleteDialog((state) => ({
      ...state,
      deleting: true,
    }));
    const { id, index } = deleteDialog;
    // delete request
    const deleted = await deleteQuestion(id, selectedLevel?._id);
    if (deleted) {
      // TODO:  show success message
      const updatedQs = [...questions];
      updatedQs?.splice(index, 1);
      setQuestions(updatedQs);
      listRef.current = updatedQs;
      // confirm box toggle
      setDeleteDialog((state) => ({
        ...state,
        open: false,
        id: null,
        index: -1,
        deleting: false,
      }));
    } else {
      // TODO: show error
    }
  };

  const discardChanges = () => {
    setQuestions(listRef.current);
    setIsListDirty(false);
    setExpanded(-1);
  };

  const saveOrder = async () => {
    setSaveLoader(true);
    const resp = await reorderQuestions(
      selectedLevel?._id,
      questions?.map((q) => q._id)
    );
    if (resp) {
      listRef.current = questions;
      setIsListDirty(false);
      setExpanded(-1);
    } else {
      // TODO: "error snakebar"
    }
    setSaveLoader(false);
  };

  const toggleQuestionDrawer = () => {
    setShowQuestionDrawer((state) => !state);
    // TODO: reset selected question & everything else
  };

  const addSelectedQuestions = async (ids) => {
    const testId = props.match?.params?.id;
    const imported = await importQuestions(testId, selectedLevel?._id, ids);
    if (!imported) {
      // TODO: show snakebar for error
    } else {
      fetchLevelQuestions(selectedLevel?._id);
    }
    toggleQuestionDrawer();
  };

  const toggleDialog = (id, index = -1) => {
    let question = null,
      options = null,
      correct = null,
      msq = false,
      tags = [];
    if (id && index !== -1) {
      question = questions[index].question;
      options = questions[index].options;
      correct = questions[index].correct;
      tags = questions[index].tags;
      msq = questions[index].msq;
    }
    setUpsertDialog((state) => ({
      ...state,
      open: !state?.open,
      id,
      question,
      options,
      tags,
      correct,
      index,
      msq,
    }));
  };

  const addQuestionCall = async (values, html) => {
    const { question, options, correct, tags, mcq } = values;
    const testId = props.match?.params?.id;
    if (!!upsertDialog?.id) {
      const question = values?.question;
      const options = values?.options;
      const correct = values?.correct;
      const tags = values?.tags;
      // update call
      const updated = await updateQuestion(upsertDialog?.id, {
        ...values,
        levelId: selectedLevel?._id,
        tags: tags?.map((tag) => tag._id) || [],
        msq: !mcq,
      });
      if (updated) {
        const updatedQs = [...questions];
        updatedQs[upsertDialog?.index] = {
          ...updatedQs[upsertDialog?.index],
          question,
          options,
          correct,
          tags,
          msq: !mcq,
        };
        setQuestions(updatedQs);
        listRef.current = updatedQs;
        //TODO: show snackbar
        toggleDialog();
      }
    } else {
      // add call
      const id = await addQuestion(testId, selectedLevel?._id, {
        ...values,
        tags: values?.tags?.map((tag) => tag._id) || [],
        msq: !mcq,
      });
      if (id) {
        setQuestions((state) => [
          ...state,
          { _id: id, question, options, correct, msq: !mcq },
        ]);
        // TODO: show snackbar
        toggleDialog();
      }
    }
  };

  const selectLevel = (e) => {
    const id = e.target.value;
    const selected = details?.levels?.find((level) => level?._id === id);
    setSelectedLevel(selected);
  };

  return (
    <div className={`flex flex-col w-full h-flex`}>
      <QuestionDrawer
        show={showQuestionDrawer}
        toggle={toggleQuestionDrawer}
        onSubmit={addSelectedQuestions}
        getAllQuestions={(tags) => getAllQuestions(selectedLevel?._id, tags)}
        getTags={getTags}
      />
      <DeleteDialog
        open={deleteDialog?.open}
        text={"Are you sure?"}
        handleClose={() => toggleDeleteDialog()}
        subText={
          "This is an irreversible operation. This question will be deleted from the test permanently."
        }
        onDelete={() => deleteQuestionCall()}
        deleting={deleteDialog?.deleting}
      />
      <UpsertQuestionForm
        open={upsertDialog?.open}
        toggle={toggleDialog}
        update={!!upsertDialog?.id}
        addQuestionCall={addQuestionCall}
        details={upsertDialog}
        getTags={getTags}
      />
      <div className={`flex w-full justify-end items-center py-4 px-4`}>
        <div className={`flex w-1/2 justify-start items-center`}>
          <h4
            className={`text-slate-600 font-semibold capitalize`}
            style={{
              fontSize: "2rem",
            }}
          >
            {locationState?.title || ""}{" "}
            <span className="text-16 text-slate-600 mx-1">
              {selectedLevel ? "(" + selectedLevel?.name + ")" : ""}
            </span>
          </h4>
        </div>
        <div className={`flex w-1/2 justify-end items-center`}>
          <div className="flex items-center">
            <TextField
              select
              id="level"
              onChange={selectLevel}
              value={selectedLevel?._id || ""}
              name="level"
              size={"small"}
              style={{ width: "8rem", margin: "0 .4rem" }}
            >
              {details?.levels?.map((level) => (
                <MenuItem value={level?._id} key={level?._id}>
                  {level?.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <Button
            style={{
              backgroundColor: "#7A4069",
              color: "white",
              borderRadius: "8px",
              padding: "8px 18px",
              marginLeft: ".6rem",
              textTransform: "capitalize",
            }}
            onClick={() => toggleQuestionDrawer()}
          >
            Import Questions
          </Button>
          <Button
            style={{
              backgroundColor: "#EF7E4F",
              color: "white",
              borderRadius: "8px",
              padding: "8px 18px",
              marginLeft: ".6rem",
              textTransform: "capitalize",
            }}
            onClick={() => toggleDialog()}
          >
            Add Question
          </Button>
        </div>
      </div>
      {loading || fetchingQs ? (
        <div
          className={`flex w-full items-center justify-center`}
          style={{
            minHeight: "22rem",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          {questions?.length === 0 && (
            <div
              className="flex w-full h-full justify-center items-center"
              style={{
                minHeight: "40vh",
              }}
            >
              <h4
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: "#444",
                }}
              >
                This test is empty. Add questions
              </h4>
            </div>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className={`flex flex-col w-full flex-1`}
                >
                  {questions?.map((question, index) => (
                    <div key={index}>
                      <QuestionAccordion
                        {...question}
                        index={index}
                        expanded={expanded === question?._id}
                        toggleAccordion={toggleAccordion}
                        deleteQuestion={() =>
                          toggleDeleteDialog(question?._id, index)
                        }
                        toggleDialog={() => toggleDialog(question?._id, index)}
                      />
                    </div>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!!isListDirty && (
              <div className={`flex w-full items-center justify-end pr-2`}>
                <Button
                  style={{
                    backgroundColor: "#e1e1e1",
                    color: "#555",
                    borderRadius: "4px",
                    padding: "8px 18px",
                    textTransform: "capitalize",
                    fontWeight: 700,
                    marginRight: ".8rem",
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                  size="small"
                  onClick={() => discardChanges()}
                >
                  Discard
                </Button>
                <Button
                  style={{
                    backgroundColor: "dodgerblue",
                    minWidth: "6rem",
                    color: "white",
                    borderRadius: "4px",
                    fontWeight: 500,
                    padding: "8px 18px",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                  size="small"
                  onClick={() => saveOrder()}
                  disable={saveLoader}
                >
                  {saveLoader ? (
                    <CircularProgress
                      size={20}
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            )}
          </DragDropContext>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSidebarOpened: state?.layout?.isSidebarOpened,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    importQuestions: (testId, levelId, questionIds) =>
      dispatch(importQuestions(testId, levelId, questionIds)),
    getQuestions: (id) => dispatch(getQuestions(id)),
    getAllQuestions: (exclude, tags) =>
      dispatch(getAllQuestions(exclude, tags)),
    getTestDetails: (id) => dispatch(getTestDetails(id)),
    reorderQuestions: (id, ordered) => dispatch(reorderQuestions(id, ordered)),
    addQuestion: (testId, levelId, values) =>
      dispatch(addQuestion(testId, levelId, values)),
    updateQuestion: (id, values) => dispatch(updateQuestion(id, values)),
    deleteQuestion: (id, testId) => dispatch(deleteQuestion(id, testId)),
    getTags: () => dispatch(getTags()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestDetailPage);
