import { TOGGLE_QUESTION_DIALOG, TOGGLE_TAG_DIALOG } from "./actionTypes";

export const toggleQuestionDialog = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_QUESTION_DIALOG,
      });
    } catch (error) {
      return false;
    }
  };
};

export const toggleTagDialog = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_TAG_DIALOG,
      });
    } catch (error) {
      return false;
    }
  };
};
