import { OPEN, CLOSE } from "../actions/actionTypes";

export const INITIAL_STATE = {
  open: false,
  message: "",
  anchorOrigin: {
    horizontal: "center",
    vertical: "top",
  },
  autoHideDuration: 4000,
  type: "success",
};

const snackbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        open: true,
        ...action.options,
      };
    case CLOSE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default snackbarReducer;
