import React from "react";

const Dashboard = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        filter: "blur(8px) grayscale(0%)",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4>Dashboard Page</h4>
      </div>
    </div>
  );
};

export default Dashboard;
