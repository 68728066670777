import {
  TOGGLE_QUESTION_DIALOG,
  TOGGLE_TAG_DIALOG,
} from "../actions/actionTypes";

export const INITIAL_STATE = {
  showQuestionDialog: false,
  showTagDialog: false,
};

const dialogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_QUESTION_DIALOG:
      return { ...state, showQuestionDialog: !state.showQuestionDialog };
    case TOGGLE_TAG_DIALOG:
      return { ...state, showTagDialog: !state.showTagDialog };
    default:
      return state;
  }
};

export default dialogReducer;
