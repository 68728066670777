import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { X } from "react-feather";

import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: "4rem",
    width: "80vw",
    minHeight: "40vh",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogComponent = (props) => {
  const classes = useStyles();
  const { title, open, handleClose, handleSubmit, saveText, saving, style } =
    props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "1rem",
        },
      }}
      classes={{
        paper: classes.dialog,
      }}
      style={{
        ...(style ? style : {}),
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#585B5E",
              fontSize: "1.5rem",
              fontWeight: 700,
            }}
          >
            {title}
          </span>
          <X
            style={{
              borderRadius: "50%",
              background: "#eaeaea",
              padding: "6px",
              cursor: "pointer",
            }}
            size={27}
            onClick={() => handleClose()}
          />
        </div>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions
        style={{
          padding: ".3rem 1.4rem 1rem 1.4rem",
        }}
      >
        <Button
          onClick={handleClose}
          style={{
            textTransform: "capitalize",
            fontSize: ".9rem",
            color: "gray",
            fontWeight: 700,
          }}
          size="small"
          disabled={saving}
        >
          Close
        </Button>
        <Button
          onClick={(event) => !saving && handleSubmit(event)}
          variant="contained"
          size="small"
          style={{
            backgroundColor: "dodgerblue",
            color: "white",
            fontSize: ".9rem",
            textTransform: "capitalize",
          }}
        >
          {saving ? (
            <CircularProgress
              size={22}
              style={{
                padding: ".2rem",
                color: "white",
              }}
            />
          ) : (
            saveText || "Save"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
