import React, { useState, useEffect, useMemo, Fragment } from "react";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Avatar, Drawer, IconButton, List } from "@mui/material";
import { useTheme } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ChevronsLeft, ChevronsRight } from "react-feather";

// styles
import { makeStyles } from "@mui/styles";

// components
import SidebarLink from "./SidebarLink";

import { toggleSidebar } from "../store/actions/layout.actions";
import { logout } from "../store/actions/auth.actions";
import {
  toggleQuestionDialog,
  toggleTagDialog,
} from "../store/actions/dialog.actions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: "1000 !important",
    height: (props) => `${props.isSidebarOpened ? "92vh" : "100vh"} !important`,
    position: "fixed",
    maxHeight: "95vh",
  },
  drawerOpen: {
    width: drawerWidth,
    boxShadow: "2px 2px 2px 0px #dadada",
    maxHeight: "96vh !important",
    margin: "1rem",
    background: "#fff!important",
    borderRadius: "1rem",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: "#fff!important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 85,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sidebarList: {
    marginTop: theme.spacing(1),
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  popover: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fab: {
    width: 36,
    height: 34,
  },
  noBoxShadow: {
    boxShadow: "none !important",
    marginRight: theme.spacing(1),
  },
  buttonLabel: {
    color: "#fff",
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#fff",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(255, 255, 255, .45)",
    },
  },
  chat: {
    width: 45,
    height: 45,
  },
  padding: {
    paddingBottom: theme.spacing(2),
  },
  height: {
    height: "80vh",
  },
  toggler: {
    background: "#3C68FA",
    position: "fixed",
    top: "5%",
    zIndex: "1001 !important",
    borderRadius: "50%",
    padding: "8px",
    cursor: "pointer",
  },
}));

function Sidebar(props) {
  let theme = useTheme();

  const {
    location,
    structure,
    isSidebarOpened,
    toggleSidebar,
    toggleTagDialog,
    toggleQuestionDialog,
    logout,
  } = props;
  let classes = useStyles({ isSidebarOpened });

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar();
  };

  // global

  // local
  let [isPermanent, setPermanent] = useState(true);

  const isSidebarOpenedWrapper = useMemo(
    () => (!isPermanent ? !isSidebarOpened : isSidebarOpened),
    [isPermanent, isSidebarOpened]
  );

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Fragment>
      {isSidebarOpened ? (
        <ChevronsLeft
          className={classes.toggler}
          onClick={() => toggleSidebar()}
          color="white"
          size={38}
          style={{
            left: 230,
          }}
        />
      ) : (
        <ChevronsRight
          className={classes.toggler}
          onClick={() => toggleSidebar()}
          color="white"
          size={38}
          style={{
            left: 60,
          }}
        />
      )}
      <Drawer
        variant={isPermanent ? "permanent" : "temporary"}
        className={`${classes.drawer} 
        ${isSidebarOpenedWrapper ? classes.drawerOpen : ""}
        ${!isSidebarOpenedWrapper ? classes.drawerClose : ""}
      `}
        classes={{
          paper: `${isSidebarOpenedWrapper ? `${classes.drawerOpen}` : ""}
          ${!isSidebarOpenedWrapper ? classes.drawerClose : ""}
        `,
        }}
        open={isSidebarOpenedWrapper}
        onClose={toggleDrawer(true)}
      >
        <div
          className={`flex w-full h-20 justify-start items-center px-6 border-b-1 border-slate-200 ${classes.toolbar}`}
        >
          <Avatar className="mr-2" sx={{ height: "35px", width: "35px" }}>
            A
          </Avatar>
          {!!isSidebarOpened && (
            <div className="flex flex-col">
              <div
                className="font-semibold text-slate-500 pl-2"
                style={{
                  fontSize: 14,
                }}
              >
                Admin
              </div>
            </div>
          )}
        </div>
        <div className={classes.mobileBackButton}>
          <IconButton onClick={() => toggleSidebar()}>
            <ArrowBackIcon
              classes={{
                root: `${classes.headerIcon} ${classes.headerIconCollapse}`,
              }}
            />
          </IconButton>
        </div>
        <List
          className={classes.sidebarList}
          classes={{ padding: classes.padding }}
        >
          {structure.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              logout={logout}
              toggleQuestionDialog={toggleQuestionDialog}
              toggleTagDialog={toggleTagDialog}
              isSidebarOpened={
                !isPermanent ? !isSidebarOpened : isSidebarOpened
              }
              {...link}
              toggleDrawer={toggleDrawer(true)}
            />
          ))}
        </List>
      </Drawer>
    </Fragment>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    let windowWidth = window.innerWidth;
    let breakpointWidth = theme.breakpoints.values.md;
    let isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isSidebarOpened: state?.layout?.isSidebarOpened,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    logout: () => dispatch(logout()),
    toggleQuestionDialog: () => dispatch(toggleQuestionDialog()),
    toggleTagDialog: () => dispatch(toggleTagDialog()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
