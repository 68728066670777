import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import App from "./App";
import { Provider } from "react-redux";
import {
  ThemeProvider as ThemeProviderV5,
  createTheme,
  CssBaseline,
} from "@mui/material";
import {
  ThemeProvider as ThemeChangeProvider,
  ThemeStateContext,
} from "./themeContext";
import "./index.css";

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeChangeProvider>
        <ThemeStateContext.Consumer>
          {(theme) => (
            <ThemeProviderV5 theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProviderV5>
          )}
        </ThemeStateContext.Consumer>
      </ThemeChangeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
