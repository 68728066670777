import React, { useState, useEffect, Fragment } from "react";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Edit2, Plus } from "react-feather";
import CategoryIcon from "@mui/icons-material/Category";

import CategoryFormDialog from "../components/categoryFormDialog";
import TestFormDialog from "../components/testFormDialog";
import {
  addTest,
  updateTest,
  addCategory,
  getCategories,
  updateCategory,
  getTests,
  deleteTest,
} from "../store/actions/test.actions";
import DeleteDialog from "../components/deleteDialog";

// const colors = [
//   "#EF7E4F",
//   "#E96ABB",
//   "#3B9AE1",
//   "#A87BF9",
//   "#2B4865",
//   "#A66CFF",
//   "#70AAF5",
// ];

//Mauve 
const mauveBase = "#42275a";
const mauveLight = "#734b6d";

//Love & Liberty 
const libertyBase = "#200122";
const liberyLight = "#6f0000";


//Lawrencium
const lawrenciumLight = "#0f0c29";
const lawrenciumBase = "#302b63";
const lawrenciumDark = "#24243e";

//Moonlit
const moonlitLight = "#2c5364";
const moonlitBase = "#0F2027";
const moonlitDark = "#203A43";

//Frost
const frostLight = "#000428";
const frostDark = "#004e92";


//Royal
const royalLight = "#141E30";
const royalDark = "#243B55";

const gradients = [
  `linear-gradient(to bottom right, ${royalLight}, ${royalDark})`,
  `linear-gradient(to bottom right, ${frostLight}, ${frostDark})`,
  `linear-gradient(to top left, ${moonlitLight}, ${moonlitBase}, ${moonlitDark})`,
  `linear-gradient(to top left, ${lawrenciumBase}, ${lawrenciumLight}, ${lawrenciumDark})`,
  `linear-gradient(to bottom right, ${libertyBase}, ${liberyLight})`,
  `linear-gradient(to bottom right, ${mauveBase}, ${mauveLight})`,

];

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  title: {
    fontSize: "1.4rem",
    paddingLeft: ".8rem",
  },
}));

const TestCard = (props) => {
  const history = useHistory();
  const { test, isSidebarOpened, editTestDetail, index } = props;
  const { _id, name, category, levels, details } = test;

  const classes = useStyles();
  // const colorCode = colors[index % colors.length];

  const randomIndex = Math.floor(Math.random() * gradients.length);

  return (
    <div
      data-aos="fade-in"
      onClick={() =>
        history.push(`/app/tests/${_id}`, {
          title: name,
        })
      }
    >
      <div
        className={`flex flex-col p-8 m-2 ${classes.card}`}
        style={{
          background: gradients[randomIndex],
          height: isSidebarOpened ? "22rem" : "20rem",
          width: isSidebarOpened ? "20rem" : "18rem",
          borderRadius: "1.4rem",
        }}
      >
        <div className={`flex w-full items-center bg-blue`}>
          <div
            className="flex w-3/4 h-8 flex-start text-12 text-white font-semibold flex-1 uppercase"
            data-aos="fade-right"
          >
            {category?.name}
          </div>
          <div className="flex w-1/4 justify-end h-8">
            <Tooltip title="Edit Details">
              <Edit2
                id={_id}
                size={16}
                color="white"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  editTestDetail(e);
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-col w-full h-full" data-aos="fade-right">
          <h4 className="font-semibold text-2xl text-white my-2 uppercase">{name}</h4>
          <div className="w-full h-28 overflow-hidden text-14 text-white">
            {details?.length > 100
              ? details?.substr(0, 100) + "..."
              : details || ""}
          </div>
          <div>
            <div data-aos="fade-right">
              <span className={`font-semibold text-white`}>
                {levels?.length || 0}&nbsp;
              </span>
              <span className={`font-semibold text-white`}>Levels</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestPage = (props) => {
  const classes = useStyles();

  const {
    isSidebarOpened,
    addCategory,
    updateTest,
    getCategories,
    getTests,
    deleteTest,
  } = props;

  const [openAddTest, setOpenAddTest] = useState({
    open: false,
    mode: "add",
    initialValues: null,
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [openCategory, setCategory] = useState(false);
  const [addCatSubmission, setAddCatSubmission] = useState(false);
  const [categories, setCategories] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [tests, setTests] = useState([]);
  const [loader, setLoader] = useState(true);

  const callApi = async () => {
    setLoader(true);
    const list = await getCategories();
    const testList = await getTests();
    setTests(testList || []);
    setCategories(list || []);
    setLoader(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  const toggleAddTest = (initialValues = null) => {
    setOpenAddTest((state) => ({
      open: !state?.open,
      initialValues,
      mode: initialValues && !state?.open ? "update" : "add",
    }));
  };

  const handleSave = async (values) => {
    const { initialValues, mode } = openAddTest;
    let index = initialValues?.index;
    let _id = initialValues?._id || null;
    if (_id && mode === "update") {
      // update test details
      const updated = await updateTest(_id, values);
      if (updated) {
        const updatedTests = [...tests];
        updatedTests[index] = {
          ...updatedTests[index],
          ...values,
        };
        setTests(updatedTests);
      }
    } else {
      // add new test
      const id = await props.addTest(values);
      setTests((tests) => [...tests, { _id: id, ...values }]);
    }
  };

  const toggleCategoryForm = () => {
    setCategory((state) => !state);
  };

  const editTestDetail = (e, details) => {
    toggleAddTest(details);
  };

  const toggleDeleteDialog = () => {
    setDeleteDialog((state) => !state);
  };

  const addCategoryCall = async (values) => {
    setAddCatSubmission(true);
    const id = await addCategory(values);
    // TODO: "show snackbar"
    setCategories((state) => [{ _id: id, ...values }, ...state]);
    setAddCatSubmission(false);
    toggleCategoryForm();
  };

  const deleteDialogCall = async () => {
    setDeleting(true);
    const id = openAddTest.initialValues._id;
    const deleted = await deleteTest(id);
    if (deleted) {
      setTests((tests) => tests.filter((test) => test._id !== id));
      toggleDeleteDialog();
      toggleAddTest();
    }
    setDeleting(false);
  };

  return (
    <div className="flex flex-col w-full h-full">
      <DeleteDialog
        open={deleteDialog}
        text={"Are you sure?"}
        handleClose={() => toggleDeleteDialog()}
        subText={
          "This is an irreversible operation. This test, all levels of it & all questions from those levels will be deleted permanently."
        }
        onDelete={() => deleteDialogCall()}
        deleting={deleting}
      />
      {loader ? (
        <div
          className="flex min-w-full justify-center items-center"
          style={{
            height: "70vh",
          }}
        >
          <CircularProgress size={32} />
        </div>
      ) : (
        <Fragment>
          <CategoryFormDialog
            open={openCategory}
            toggle={toggleCategoryForm}
            addCategoryCall={addCategoryCall}
            addCatSubmission={addCatSubmission}
          />
          <TestFormDialog
            open={openAddTest?.open}
            toggle={toggleAddTest}
            addTest={handleSave}
            categories={categories}
            mode={openAddTest?.mode}
            initializeWith={openAddTest?.initialValues}
            toggleCategoryForm={toggleCategoryForm}
            toggleDeleteDialog={toggleDeleteDialog}
            style={{
              display: openCategory ? "none" : "",
            }}
          />
          <div className="flex w-full items-center mb-4">
            <div className="flex w-1/2 items-center">
              <span className={`font-semibold text-slate-500 ${classes.title}`}>
                Tests
              </span>
            </div>
            {!!tests?.length && (
              <div className="flex w-1/2 items-center justify-end">
                <Button
                  style={{
                    backgroundColor: "#F77E21",
                    color: "white",
                    borderRadius: "18px",
                    padding: "8px 18px",
                    marginRight: ".6rem",
                    textTransform: "capitalize",
                    marginRight: ".7rem",
                  }}
                  onClick={() => toggleAddTest()}
                >
                  <span className="mr-2">
                    <Plus size={20} color="white" />
                  </span>
                  Add New Test
                </Button>
                <Button
                  style={{
                    backgroundColor: "#7A4069",
                    color: "white",
                    borderRadius: "18px",
                    padding: "8px 18px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => toggleCategoryForm()}
                >
                  <span className="mr-2">
                    <CategoryIcon
                      style={{
                        color: "white",
                        fontSize: "1.3rem",
                      }}
                    />
                  </span>
                  Add Category
                </Button>
              </div>
            )}
          </div>
          <div className="flex w-full items-center flex-wrap gap-4">
            {tests?.length === 0 && (
              <div
                className="flex w-full h-full flex-col items-center justify-center"
                style={{
                  height: "70vh",
                }}
              >
                <h4
                  className="text-slate-600"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    marginBottom: "1rem",
                  }}
                >
                  No test found
                </h4>
                <Button
                  style={{
                    backgroundColor: "#F77E21",
                    color: "white",
                    borderRadius: "18px",
                    padding: "8px 18px",
                    marginRight: ".6rem",
                    textTransform: "capitalize",
                    marginRight: ".7rem",
                    width: "10rem",
                  }}
                  onClick={() => toggleAddTest()}
                >
                  <span className="mr-2">
                    <Plus size={20} color="white" />
                  </span>
                  Add New Test
                </Button>
              </div>
            )}
            {tests?.map((test, index) => (
              <TestCard
                test={test}
                key={test?._id}
                index={index}
                isSidebarOpened={isSidebarOpened}
                editTestDetail={(e) => editTestDetail(e, { ...test, index })}
              />
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSidebarOpened: state?.layout?.isSidebarOpened,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTests: () => dispatch(getTests()),
    addTest: (values) => dispatch(addTest(values)),
    updateTest: (id, values) => dispatch(updateTest(id, values)),
    addCategory: (values) => dispatch(addCategory(values)),
    getCategories: () => dispatch(getCategories()),
    updateCategory: (values) => dispatch(updateCategory(values)),
    deleteTest: (id) => dispatch(deleteTest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
