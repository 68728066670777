export const LOGGED_IN = "LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const SET_PROFILE = "SET_PROFILE";

export const OPEN = "OPEN";
export const CLOSE = "CLOSE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";

export const ADD_TEST = "ADD_TEST";
export const UPDATE_TEST = "UPDATE_TEST";
export const DELETE_TEST = "DELETE_TEST";
export const GET_TESTS = "GET_TESTS";

export const TOGGLE_QUESTION_DIALOG = "TOGGLE_QUESTION_DIALOG";
export const TOGGLE_TAG_DIALOG = "TOGGLE_TAG_DIALOG";
