import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Draggable } from "react-beautiful-dnd";
import { Check, Edit3, Trash2 } from "react-feather";
import { Tooltip } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  background: "#F5F8FB",
  borderRadius: "8px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => {
  return (
    <MuiAccordionSummary
      expandIcon={
        <span
          className="font-semibold"
          style={{
            height: "100%",
            display: "flex",
            alignSelf: "start",
          }}
        >
          {props?.index + 1}.
        </span>
      }
      {...props}
    />
  );
})(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  borderRadius: "8px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    alignSelf: "start",
    paddingTop: ".8rem",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid #dadada",
  paddingLeft: "3.2rem",
  borderBottomRightRadius: "8px",
  borderBottomLeftRadius: "8px",
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
}));

const QuestionAccordion = (props) => {
  const {
    _id,
    index,
    expanded,
    question,
    options,
    deleteQuestion,
    correct,
    toggleAccordion,
    toggleDialog,
  } = props;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    padding: "8px",
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#DBF0EE" : "",
    marginBottom: "12px",
    borderRadius: "8px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable key={_id} draggableId={`${_id}`} index={index}>
      {(provided, snapshot) => (
        <div
          className={`flex w-full overflow-hidden`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Accordion expanded={expanded} onChange={toggleAccordion(_id)}>
            <AccordionSummary index={index} sx={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "73vw",
                  flexGrow: 1,
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: question }} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={`flex w-full flex-wrap relative`}>
                <div
                  className="flex w-24 items-center justify-end"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                >
                  <Tooltip title="Remove question">
                    <Trash2
                      size={25}
                      color="red"
                      className={`cursor-pointer mb-1 mr-3`}
                      onClick={() => deleteQuestion(_id)}
                    />
                  </Tooltip>
                  <Tooltip title="Edit question">
                    <Edit3
                      size={25}
                      className={`cursor-pointer`}
                      onClick={() => toggleDialog(_id)}
                    />
                  </Tooltip>
                </div>
                {options?.map((option, index) => {
                  const answer = correct?.includes(index + 1);

                  return (
                    <div
                      className={`flex items-center w-1/2 mb-4 ${
                        index % 2 == 0 ? "justify-start" : "justify-start"
                      }`}
                      key={index}
                    >
                      <div
                        className={`flex w-3/4 justify-start items-center`}
                        style={{
                          border: `1px solid ${answer ? "#06BC80" : "#e1e1e1"}`,
                          borderRadius: "8px",
                          padding: "8px",
                          width: "22rem",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: 700,
                            padding: answer ? "4px" : "4px 10px",
                            background: answer ? "#06BC80" : "#dfdfdf",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        >
                          {answer ? (
                            <Check size={20} color="white" />
                          ) : (
                            String.fromCharCode(65 + index)
                          )}
                        </span>
                        <span>{option?.text}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Draggable>
  );
};

export default QuestionAccordion;
