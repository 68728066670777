import React from "react";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Edit3, Trash2 } from "react-feather";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "16px 24px",
    width: "22%",
    height: "84px",
    // boxShadow: "0px 15px 50px rgba(160, 163, 189, 0.1)",
    borderRadius: "10px",
    margin: "16px",
    boxSizing: "border-box",
    marginBottom: "-10px",
    background: "white",
    // flexShrink: 1,
  },
}));

const colors = [
  "rgba(241, 90, 36, 0.08)",
  "rgba(82, 39, 133, 0.08)",
  "rgba(232, 42, 123, 0.08)",
  "rgba(42, 232, 23, 0.08)",
  "rgba(92, 232, 23, 0.08)",
];

const MiniCard = (props) => {
  const {
    id,
    icon = "/icons/ellipsis.svg",
    title,
    clickHandler,
    sidebar,
    index,
    customStyles,
    toggleDeleteDialog,
    deleteKey,
  } = props;

  const classes = useStyles({ sidebar });

  return (
    <div
      className={`flex ${classes.card} cursor-pointer mb-2`}
      // onClick={clickHandler}
    >
      {/* {icon ? (
        <img
          src={icon}
          className="flex w-1/6 justify-center items-center"
          style={{
            background: colors[index % colors.length],
            borderRadius: "50px",
            padding: "11px",
            width: "58px",
            height: "58px",
          }}
        />
      ) : (
        <div
          className="flex w-1/6 justify-center items-center"
          style={{
            background: colors[index % colors.length],
            borderRadius: "50px",
            padding: "14px",
            width: "52px",
            height: "52px",
          }}
        />
      )} */}
      <div className="flex w-5/6 justify-start items-center truncate uppercase">
        <Tooltip title={title || ""}>
          <div
            className="truncate"
            style={{
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "31px",
              margin: "0px 2px",
              paddingLeft: "13px",
              ...(customStyles ? customStyles : {}),
            }}
          >
            {title}
          </div>
        </Tooltip>
      </div>
      <Tooltip title="edit category">
        <div
          className="flex w-1/6 justify-center items-center"
          style={{
            fontSize: "20px",
            color: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Edit3
            size={20}
            color="#444"
            onClick={() => clickHandler()}
            style={{
              fontWeight: 700,
            }}
          />
        </div>
      </Tooltip>
      {deleteKey ? (
        <Tooltip title="delete category">
          <div
            className="flex w-1/6 justify-center items-center"
            style={{
              fontSize: "20px",
              color: "#EA5455",
            }}
          >
            <Trash2
              size={20}
              color="#EA5455"
              onClick={() => toggleDeleteDialog(id, index)}
              style={{
                fontWeight: 700,
              }}
            />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default MiniCard;
