import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from "@mui/styles";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  toolbar: {},
  editor: {
    border: "1px solid #dadada",
    padding: "0px 8px",
    minHeight: "18vh",
    maxHeight: "18vh",
    lineHeight: 0.6,
  },
}));

const EditorContainer = (props) => {
  const styles = useStyles();

  const onEditorStateChange = (editorState) => {
    props.setEditorState(editorState);
  };

  return (
    <div className="editor">
      <Editor
        editorState={props.editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        toolbarClassName={styles.toolbar}
        editorClassName={styles.editor}
        placeholder="Question"
      />
    </div>
  );
};

export default EditorContainer;
